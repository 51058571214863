import Vue from 'vue';
import VueRouter from 'vue-router';
import OktaVue from '@okta/okta-vue';
import { OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback } from '@/components/auth';
import HomePage from '@/views/home-page.vue';
import MarinLitList from '@/views/marinlit/article-list.vue';
import MarinLitEdit from '@/views/marinlit/article-edit.vue';
import MerckIndexList from '@/views/merckindex/monograph-list.vue';
import MerckIndexEdit from '@/views/merckindex/monograph-edit.vue';
import CSDepositions from '@/views/cs-depositions/cs-depositions-list.vue';
import CSDepositionsEdit from '@/views/cs-depositions/cs-depositions-edit.vue';
import CSDepositionsAdd from '@/views/cs-depositions/cs-depositions-add.vue';
import CSDatasources from '@/views/cs-depositions/cs-datasources-list.vue';
import CSDatasourcesView from '@/views/cs-depositions/cs-datasources-view.vue';
import CSDatasourcesEdit from '@/views/cs-depositions/cs-datasources-edit.vue';
import CSDatasourcesAdd from '@/views/cs-depositions/cs-datasources-add.vue';
import PageNotFound from '@/views/page-not-found.vue';

const oktaConfig = {
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  issuer: process.env.VUE_APP_OKTA_ISSUER,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
};

const oktaAuth = new OktaAuth(oktaConfig);

Vue.use(VueRouter);
Vue.use(OktaVue, { oktaAuth });

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { elementId: 'page-home' },
  },
  {
    path: '/login/callback',
    component: LoginCallback,
  },
  // As well as <router-link> elements, route names are used by some shared 
  // components and mixins, and should not be changed unless necessary.
  {
    path: '/marinlit',
    name: 'MarinLit',
    component: MarinLitList,
    meta: { elementId: 'page-marinlit-list', requiresAuth: true },
  },
  {
    // ID optional as articles can be created from scratch at /marinlit/edit
    path: '/marinlit/edit/:id?',
    name: 'MarinLitEdit',
    component: MarinLitEdit,
    meta: { elementId: 'page-marinlit-edit', requiresAuth: true },
  },
  {
    path: '/merckindex',
    name: 'MerckIndex',
    component: MerckIndexList,
    meta: { elementId: 'page-merckindex-list', requiresAuth: true },
  },
  {
    path: '/merckindex/edit/:id',
    name: 'MerckIndexEdit',
    component: MerckIndexEdit,
    meta: { elementId: 'page-merckindex-edit', requiresAuth: true },
  },
  {
    path: '/cs-depositions',
    name: 'CSDepositions',
    component: CSDepositions,
    meta: { elementId: 'page-cs-depositions', requiresAuth: false },
  },
  {
    path: '/cs-depositions/edit/:id',
    name: 'CSDepositionsEdit',
    component: CSDepositionsEdit,
    meta: { elementId: 'page-cs-depositions-edit', requiresAuth: false },
  },
  {
    path: '/cs-depositions/add',
    name: 'CSDepositionsAdd',
    component: CSDepositionsAdd,
    meta: { elementId: 'page-cs-depositions-add', requiresAuth: false },
  },
  {
    path: '/cs-depositions/datasources',
    name: 'CSDatasources',
    component: CSDatasources,
    meta: { elementId: 'page-cs-datasources', requiresAuth: false },
  },
  {
    path: '/cs-depositions/datasource/add',
    name: 'CSDatasourcesAdd',
    component: CSDatasourcesAdd,
    meta: { elementId: 'page-cs-datasources-add', requiresAuth: false },
  },
  {
    path: '/cs-depositions/datasource/edit/:id',
    name: 'CSDatasourcesEdit',
    component: CSDatasourcesEdit,
    meta: { elementId: 'page-cs-datasources-edit', requiresAuth: false },
  },
  {
    path: '/cs-depositions/datasource/:id',
    name: 'CSDatasourcesView',
    component: CSDatasourcesView,
    meta: { elementId: 'page-cs-datasources-view', requiresAuth: false },
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: { elementId: 'page-not-found' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
});

export default router;
