<template>
  <section class="edit-datasource">
    <AddEditDatasource />
  </section>
</template>

<script>
import AddEditDatasource from '@/components/cs-depositions/shared/addEditDatasource.vue'


export default {
  name: 'editDataSource',
  components: {
    AddEditDatasource
  },

};
</script>