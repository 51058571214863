<template>
  <div class="modal create-new-monograph is-active">
    <div class="modal-background" @click="$emit('close')"></div>
    <ValidationObserver tag="div" v-slot="{ handleSubmit }">
      <form class="modal-card" @submit.prevent="handleSubmit(saveMonograph)">

        <header class="modal-card-head">
          <p class="modal-card-title">Create new monograph</p>
        </header>

        <section class="modal-card-body">
          <fieldset :disabled="isFormSaving">
            <MessageBlock v-if="saveError" class="is-danger" heading="Failed to save">{{ saveError }}</MessageBlock>
            <TextInput id="title" ref="title" v-model="monograph.title" rules="required" label="Title" class="mb-5" />
            <TextArea id="justification" v-model="monograph.justification" rules="required" label="Justification" />
          </fieldset>
        </section>

        <footer class="modal-card-foot">
          <button type="submit" class="button is-success footer-buttons-03" :class="{ 'is-loading': isFormSaving }">
            Save new draft and edit
          </button>
          <button type="button" class="button footer-buttons-03" @click="$emit('close')" :disabled="isFormSaving">
            Cancel
          </button>
        </footer>

      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { MerckIndexDataService } from '@/api';
import { TextArea, TextInput } from '@/components/forms';
import { MessageBlock } from '@/components/shared';
import { WorkflowStatus } from '@/workflow-status';
import { v4 as uuidv4 } from 'uuid';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'CreateNewMonograph',
  data() {
    return {
      monograph: {
        title: '',
        justification: '',
      },
      isFormSaving: false,
      saveError: null,
    };
  },
  mounted() {
    // Not pretty, but it saves a click...
    this.$refs.title.$el.children[0].children[0].focus();
  },
  methods: {
    prepareMonograph() {
      return {
        articleId: uuidv4(),
        title: this.monograph.title.trim(),
        status: WorkflowStatus.DRAFT,
        substances: [{
          recordId: uuidv4(),
          justification: this.monograph.justification.trim(),
          title: this.monograph.title.trim(),
        }]
      };
    },
    async saveMonograph() {
      const monograph = this.prepareMonograph();
      this.isFormSaving = true;
      const result = await MerckIndexDataService.addNewMonograph(monograph);
      if (result.Data && result.Data.ok) {
        this.$router.replace({
          name: 'MerckIndexEdit',
          params: { id: result.Data.articleId }
        });
      }
      else {
        this.isFormSaving = false;
        const error = result.ServiceStatus.Error;
        this.saveError = error.statusText ? error.statusText : error.errorMessage;
      }
    },
  },
  components: {
    MessageBlock,
    TextArea,
    TextInput,
    ValidationObserver,
  },
};
</script>
