<template>
  <span class="tag pill mr-3 limited-to-page-width" style="bg-danger d-block w-100" :class="{ 'is-danger': !!this.errors.length }" >
    {{ param.label }}: {{ param.value }}
    <button type="button" class="delete ml-2" @click="$emit('removeParameter')">
      Remove
    </button>
  </span>
</template>

<script>
export default {
  name: 'SearchPill',
  props: {
    param: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    },
  },
};
</script>

<style lang="scss" scoped>
.limited-to-page-width {  
  white-space: normal;
  height: 100%;
}

</style>
