<template>
  <ModalWindow
    class="create-new-article"
    :isOpen="true"
    :disabled="isFormLocked"
    heading="Create new article from"
    @close="$emit('close')"
  >
    <template #body>
      <div class="columns is-mobile mb-5">
        <div class="column is-5">
          <TextInput
            customKeydownEnter
            id="doiSearch"
            ref="doiSearch"
            label="Article DOI"
            placeholder="e.g. 10.1039/D0RA00894J"
            @keydown-enter="getArticleFromDoi"
            v-model.trim="doi">
            <template #addons>
              <div class="control">
                <button type="button" id="btn-doi-search" class="button is-success" :class="{ 'is-loading': isFormLocked }" @click="getArticleFromDoi">
                  Search
                </button>
              </div>
            </template>
          </TextInput>
        </div>
        <div class="column">
          <TextInput
            customKeydownEnter
            id="pubMedIdSearch"
            label="PubMed ID"
            placeholder="e.g. 25611519"
            @keydown-enter="getArticleFromPubMedId"
            v-model.trim="pubMedId">
            <template #addons>
              <div class="control">
                <button type="button" class="button" :class="{ 'is-loading': isFormLocked }" @click="getArticleFromPubMedId">
                  Search
                </button>
              </div>
            </template>
          </TextInput>
        </div>
        <div class="column is-narrow">
          <label class="label">References</label>
          <label for="fileInput">
            <div class="button" :class="{ 'is-loading': isFormLocked }">
              <span class="icon"><i class="fas fa-upload"></i></span>
              <span>Upload .bib file</span>
            </div>
            <input type="file" @change="onFileChanged" accept=".bib" class="is-hidden" id="fileInput" />
          </label>
        </div>
        <div class="column is-narrow">
          <label class="label">Empty template</label>
          <router-link :to="{ name: 'MarinLitEdit' }" id="btn-create-from-scratch" class="button" :class="{ 'is-loading': isFormLocked }">
            Create from scratch
          </router-link>
        </div>
      </div>
      
      <!-- Search error -->
      <MessageBlock v-if="SearchServiceStatus.HasError" class="is-danger" :heading="SearchServiceStatus.Error.message">
        <p>Nothing is available for that search term, or there was a problem fetching data.</p>
      </MessageBlock>
        
      <!-- Save error -->
      <MessageBlock v-if="saveError" class="is-danger" heading="Failed to save">
        <ul v-if="saveValidationErrors">
          <li v-for="error in saveValidationErrors" :key="error">{{ error }}</li>
        </ul>
      </MessageBlock>
      
      <!-- New articles -->
      <MessageBlock v-if="newArticles.length && !saveError" class="is-success" :heading="newArticlesMessage" />

      <!-- Duplicate articles -->
      <MessageBlock v-if="duplicateArticles.length && !saveError" class="is-danger" :heading="duplicateArticlesMessage">
        <ul v-if="SearchResults.length > 1">
          <li v-for="(r, index) in duplicateArticles" :key="index">{{ r.article.doi }}</li>
        </ul>
      </MessageBlock>
      
      <!-- Search results -->
      <div v-for="(r, index) in SearchResults" :key="index" class="columns is-gapless is-mobile my-5">
        <div class="column is-narrow">
          <span v-show="!r.isDuplicate" class="icon mr-2"><i class="fas fa-check"></i></span>
          <span v-show="r.isDuplicate" class="icon mr-2"><i class="fas fa-times"></i></span>
        </div>
        <div class="column">
          <p v-html="r.article.title"></p>
          <p v-if="r.article.authors"><ArticleAuthors :authors="r.article.authors" /></p>
          <p v-if="r.article.journalTitle"><ArticleCitation :article="r.article" /></p>
          <p v-if="r.article.doi"><ArticleDoiLink :doi="r.article.doi" /></p>
        </div>
      </div>
    </template>

    <template #footer>
      <button
        type="button"
        class="button is-success"
        :class="{ 'is-loading': isFormLocked }"
        :disabled="!newArticles.length || isFormLocked"
        @click="saveButtonHandler">
        {{ saveButtonText }}
      </button>
    </template>

  </ModalWindow>
</template>

<script>
import bibtexParse from '@orcid/bibtex-parse-js';
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { MarinLitDataService } from '@/api';
import { WorkflowStatus } from '@/workflow-status';
import { TextInput } from '@/components/forms';
import { ArticleAuthors, ArticleCitation, ArticleDoiLink, MessageBlock, ModalWindow } from '@/components/shared';
import ArticleDataMixin from '@/mixins/marinlit/article-data-mixin';

export default {
  name: 'CreateNewArticle',
  mixins: [ArticleDataMixin],
  data() {
    return {
      doi: '',
      pubMedId: '',
      isSaving: false,
      saveError: null,
    }
  },
  created() {
    this.resetForm();
    this.resetArticleEditorAction();
  },
  mounted() {
    this.$refs.doiSearch.$el.children[0].focus();
  },
  methods: {
    async onFileChanged(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      await this.handleFile(files[0]);
      e.target.value = null;
    },
    getArticlesFromCitationData(citationData) {
      let articles = [];
      const bibtexArticles = bibtexParse.toJSON(citationData).filter(entry => entry.entryType === 'Article');
      for (const article of bibtexArticles) {
        articles.push({
          ...article.entryTags, // Destructure title, author, journal etc.
          key: article.citationKey,
          entryType: article.entryType
        });
      }
      return articles;
    },
    async handleFile(file) {
      let reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const bibtexCitations = this.getArticlesFromCitationData(data);
        await this.getMultipleArticles(bibtexCitations);
      };
      reader.readAsText(file);
    },
    resetForm() {
      this.doi = '';
      this.pubMedId = '';
      this.isSaving = false;
      this.saveError = null;
      this.clearArticleSearchResultsAction();
    },
    async getArticleFromDoi() {
      this.doi = this.doi.split('doi.org/').pop();
      if (this.doi) {
        this.saveError = null;
        await this.updateSingleArticleSearchResultAction({ value: this.doi, type: 'doi' });
      }
    },
    async getArticleFromPubMedId() {
      if (this.pubMedId) {
        this.saveError = null;
        await this.updateSingleArticleSearchResultAction({ value: this.pubMedId, type: 'pubMedId' });
      }
    },
    async getMultipleArticles(bibtexCitations) {
      this.saveError = null;
      await this.updateMultipleArticleSearchResultAction(bibtexCitations);
    },
    ...mapActions('MarinLitImporter', [
      'clearArticleSearchResultsAction',
      'updateSingleArticleSearchResultAction',
      'updateMultipleArticleSearchResultAction',
    ]),
    ...mapActions('MarinLitEditor', [
      'resetArticleEditorAction',
    ]),
    async saveButtonHandler() {
      this.isSaving = true;
      
      // Save one new article
      if (this.newArticles.length === 1) {
        const article = this.newArticles[0].article;
        const result = await this.dbAddSingleArticle(article);
        if (result.Data && result.Data.ok) {
          this.$router.push({
            name: 'MarinLitEdit',
            params: { id: result.Data.articleId }
          });
        }
        else {
          this.saveError = result.ServiceStatus.Error;
          this.isSaving = false;
        }
      }
      
      // Save multiple new articles
      else if (this.newArticles.length > 1) {
        // Generate the new IDs here, so that any individual failures
        // can be linked back to their DOIs and explained in the UI.
        const articles = this.newArticles.map(obj => ({
          ...obj.article,
          articleId: uuidv4(),
          status: WorkflowStatus.DRAFT,
        }));

        // Try to save the new articles...
        const result = await MarinLitDataService.addNewArticles(articles);
        if (result.Data && result.Data.length) {
          
          // Handle any individual failures
          const failures = result.Data.filter(obj => !obj.ok);
          this.handleMultipleSaveErrors(failures, articles);

          // Stop here if every article failed to save
          if (failures.length === articles.length) {
            this.resetForm();
          }
          
          // Otherwise poll the search API until it reflects one successful
          // save, then close the modal and reload the article list.
          else {
            const savedArticle = result.Data.find(a => a.ok);
            this.statusUpdateCheckInterval = setInterval(async () => {
              const ready = await this.verifyArticleStatusUpdate(savedArticle.articleId, WorkflowStatus.DRAFT, savedArticle.status);
              if (ready) {
                this.isSaving = false;
                this.selectedStatus = WorkflowStatus.DRAFT; // Switch to 'Draft' list
                this.$emit('close', true);
              }
            }, 1000);
          }
        }
        else {
          this.saveError = result.ServiceStatus.Error;
          this.isSaving = false;
        }
      }
    },
    handleMultipleSaveErrors(failures, articles) {
      // This should happen rarely, if ever, because we pre-validated the
      // articles in the .bib file before the user pressed save.
      if (failures.length) {
        let msg = `Sorry, ${failures.length} of ${articles.length} articles failed to save:\n`
        const errors = failures.map(obj => ({
          doi: articles.find(a => a.articleId === obj.articleId).doi,
          msg: Object.values(obj.validationErrors)[0],
        }));
        for (const error of errors) {
          msg += `\n• ${error.doi} (${error.msg})`;
        }
        window.alert(msg);
      }
    },
  },
  computed: {
    ...mapState('MarinLitImporter', {
      SearchResults: state => state.SearchResults,
      SearchServiceStatus: state => state.ServiceStatus,
    }),
    ...mapGetters('MarinLitImporter', [
      'isLoading',
      'newArticles',
      'duplicateArticles'
    ]),
    ...mapFields('MarinLitSearch', {
      selectedStatus: 'SearchParams.status.value',
    }),
    isFormLocked() {
      return this.isLoading || this.isSaving;
    },
    newArticlesMessage() {
      return this.SearchResults.length === 1
        ? 'This article is new to MarinLit'
        : `${this.newArticles.length} of ${this.SearchResults.length} articles are new to MarinLit`;
    },
    duplicateArticlesMessage() {
      return this.SearchResults.length === 1
        ? 'That article already exists in MarinLit'
        : `${this.duplicateArticles.length} of ${this.SearchResults.length} articles already exist in MarinLit`;
    },
    saveButtonText() {
      return `Save
                ${this.newArticles.length === 1 ? 'new draft and edit' : ''}
                ${this.newArticles.length > 1 ? this.newArticles.length + ' new drafts' : ''}`;
    },
    saveValidationErrors() {
      const validationErrors = this.saveError.validationErrors && Object.keys(this.saveError.validationErrors).length
        ? this.saveError.validationErrors
        : null;
      if (validationErrors) {
        return Object.values(validationErrors);
      }
      else {
        // Something else went wrong, e.g. API unavailable, missing article, batch size exceeded
        return this.saveError.statusText ? [this.saveError.statusText] : [this.saveError.errorMessage];
      }
    },
  },
  components: {
    ArticleAuthors,
    ArticleCitation,
    ArticleDoiLink,
    MessageBlock,
    ModalWindow,
    TextInput,
  },
}
</script>
