<template>
  <div>

    <StatusLinks id="region-status-links" :statusWiseRecords="statusWiseRecords" @click="updateSearchResults" />

    <ValidationObserver tag="div" class="columns is-mobile" v-slot="{ errors }">
      
      <!-- SEARCH -->
      <div id="region-sidebar" class="column is-one-third">
        
        <h2 class="title list-page-heading">Search</h2>
        
        <!-- Container -->
        <TogglePanel heading="Container" id="box-search-container" expanded>
          <TextInput id="containerName" v-model="containerName" label="Name" @input="debouncedSearch" class="mb-4" />
        </TogglePanel>

        <!-- Metadata -->
        <TogglePanel heading="Metadata" id="box-search-metadata" expanded>
          <label class="label" for="createdBy">Created by</label>
          <v-select v-model="createdBy" placeholder="Select user..." inputId="createdBy" :options="users" @input="updateAll" class="mb-4" />
          <label class="label" for="lastModifiedBy">Last modified by</label>
          <v-select v-model="lastModifiedBy" placeholder="Select user..." inputId="lastModifiedBy" :options="users" @input="updateAll" class="mb-4" />
          <label class="label">Published date</label>
          <div class="columns is-mobile is-gapless is-vcentered mb-4">
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="datePublishedFrom" @input="updateAll" /></div>
            <div class="column has-text-centered">to</div>
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="datePublishedTo" @input="updateAll" /></div>
          </div>
          <label class="label">Created date</label>
          <div class="columns is-mobile is-gapless is-vcentered mb-4">
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="dateCreatedFrom" @input="updateAll" /></div>
            <div class="column has-text-centered">to</div>
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="dateCreatedTo" @input="updateAll" /></div>
          </div>
          <label class="label">Last modified date</label>
          <div class="columns is-mobile is-gapless is-vcentered mb-4">
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="dateLastModifiedFrom" @input="updateAll" /></div>
            <div class="column has-text-centered">to</div>
            <div class="column"><input type="date" :min="minDate" :max="today" v-model="dateLastModifiedTo" @input="updateAll" /></div>
          </div>
        </TogglePanel>
        
      </div>

      <!-- MONOGRAPHS -->
      <div id="region-content" class="column is-two-thirds">
        
        <CreateNewMonograph v-if="isCreateMonographWindowOpen" @close="isCreateMonographWindowOpen = false" />
        
        <button type="button" id="btn-create-new-monograph" @click="isCreateMonographWindowOpen = true" class="button is-link is-outlined is-medium is-pulled-right">
          Create new monograph
        </button>
         
        <h2 class="title list-page-heading">
          <span id="number-monograph-list-heading"> {{ numberResultsListHeading }} </span>
          <span id="text-monograph-list-heading">{{ searchResultsListHeading }}</span>
        </h2>
        
        
        <!-- Search pills -->
        <div v-if="Object.keys(getSearchPills).length" class="tags are-medium">
          <SearchPill
            v-for="(param, id) in getSearchPills"
            :key="`${id}Pill`"
            :param="param"
            :errors="errors[id]"
            @removeParameter="removeSearchParameter(id)" />
        </div>
        
        <!-- Loading error -->
        <MessageBlock v-if="searchResultsError" class="is-danger">
          <p>There was a problem fetching the monograph list. Try refreshing the page.</p>
        </MessageBlock>
        
        <!-- Monograph list -->
        <MonographResult v-for="monograph in searchResults" :key="monograph.articleId" :monograph="monograph" @update="updateByResult" />
        
      </div>
      
    </ValidationObserver>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapGetters } from 'vuex';
import { CreateNewMonograph, MonographResult } from '@/components/merckindex/list';
import ListPageMixin from '@/mixins/shared/list-page-mixin';

export default {
  name: 'MerckIndexList',
  metaInfo: {
    title: 'Merck Index',
  },
  mixins: [ListPageMixin],
  data() {
    return {
      minDate: '2013-01-01',
      isCreateMonographWindowOpen: false,
    };
  },
  methods: {
    updateByResult(){
      this.updateAll()
    }
  },
  computed: {
    ...mapFields('MerckIndexSearch', {
      users: 'UserList',
      status: 'SearchParams.status.value',
      containerName: 'SearchParams.containerName.value',
      createdBy: 'SearchParams.createdBy.value',
      lastModifiedBy: 'SearchParams.lastModifiedBy.value',
      datePublishedFrom: 'SearchParams.datePublishedFrom.value',
      datePublishedTo: 'SearchParams.datePublishedTo.value',
      dateCreatedFrom: 'SearchParams.dateCreatedFrom.value',
      dateCreatedTo: 'SearchParams.dateCreatedTo.value',
      dateLastModifiedFrom: 'SearchParams.dateLastModifiedFrom.value',
      dateLastModifiedTo: 'SearchParams.dateLastModifiedTo.value',
    }),
    ...mapGetters('MerckIndexSearch', [
      'getSearchPills',
      'getSearchQueryString',
      'getSearchQueryStringWithoutStatus',
    ]),
  },
  components: {
    CreateNewMonograph,
    MonographResult,
  },
};
</script>
