import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

// For required text fields and select elements
extend('required', {
  ...required,
  message: 'This field is required'
});

// For all numeric input fields
extend('number', {
  validate: value => !isNaN(value),
  message: 'Must be a number'
});

// For location depth, exact mass, UV values and Log Ɛ
extend('non_negative', {
  validate: value => value >= 0,
  message: 'Must be non-negative'
});

// For latitude and longitude
extend('min_max', {
  validate(value, { min, max }) {
    return Number(value) >= min && Number(value) <= max;
  },
  params: ['min', 'max'],
  message: 'Must be from {min} to {max}'
});

// For DOI
extend('doi', {
  validate: value => value.startsWith('10.'),
  message: 'Must start with 10.'
});

// For Standard InChI
extend('inchi', {
  validate: value => value.startsWith('InChI='),
  message: 'Must start with InChI='
});

// For InChIKey
extend('inchikey', {
  validate: value => value.length === 27
                  && value[14] === '-'
                  && value[25] === '-'
                                 // Regex Fragment      | Meaning
                                 // ========================================
                                 // ^       $           | Start and end of string
                                 //  [A-Z-]*            | All characters are A-Z or dashes
                  && !!value.match(/^[A-Z-]*$/),
  message: 'Must be 27 characters long (A-Z and dashes only)'
});

// For MarinLit (Article) ID
extend('articleId', {
                                 // Regex Fragment      | Meaning
                                 // ========================================
                                 // ^         $         | Start and end of string
                                 //  [A]                | First character is 'A'
                                 //     [0-9]*          | Any subsequent characters are numbers
  validate: value => !!value.match(/^[A][0-9]*$/)
                  && value.length > 1,
  message: 'Must be \'A\' followed by a number'
});

// For Compound MarinLit ID
extend('compoundMarinLitId', {
                                 // Regex Fragment      | Meaning
                                 // ========================================
                                 // ^         $         | Start and end of string
                                 //  [L]                | First character is 'L'
                                 //     [0-9]*          | Any subsequent characters are numbers
  validate: value => !!value.match(/^[L][0-9]*$/)
                  && value.length > 1,
  message: 'Must be \'L\' followed by a number'
});
