<template>
  <div>
    <ArticleInfo :article="article" />
    <hr />

    <MessageBlock v-if="saveError" heading="Failed to save article" class="is-danger">
      <p>{{ saveError }}</p>
    </MessageBlock>

    <form @submit.prevent="saveArticle">

      <fieldset :disabled="isFormLocked">
    
        <!-- ROW 1 (Trivial names) -->
        <div class="columns is-mobile mt-2">
          <div class="column is-three-fifths">
            <div class="columns is-mobile">
              <div class="column is-one-fifth">
                <label for="trivialNames" class="label">Trivial name(s)</label>
              </div>
              <div class="column">
                <!--
                  https://github.com/sagalbot/vue-select/issues/668 describes a workaround for
                  vue-select's multiple/taggable/filterable bug, using :filter-by="() => true"
                -->
                <v-select multiple taggable
                  v-model="trivialNames"
                  inputId="trivialNames"
                  ref="trivialNames"
                  :filterable="false"
                  :filter-by="() => true"
                  :disabled="isFormLocked"
                  :options="trivialNameSuggestions"
                  :create-option="option => option.toLowerCase().trim()"
                  @search="debouncedGetTrivialNameSuggestions"
                  @search:focus="trivialNamesFocused = true"
                  @search:blur="trivialNamesFocused = false">
                  <template #option="option">
                    <span v-if="!trivialNameSuggestions.includes(option.label)" class="tag is-info">New</span>
                    {{ option.label }}
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <!-- ROW 2 (Categories) -->
        <div class="columns is-mobile mt-2">
          <div class="column is-three-fifths">
            <div class="columns is-mobile">
              <div class="column is-one-fifth">
                <label for="categories" class="label">Categories</label>
              </div>
              <div class="column">
                <div class="control">
                  <v-select multiple
                    v-model="categories"
                    inputId="categories"
                    label="subcategory"
                    :disabled="isFormLocked"
                    @search:focus="isCategoriesModalOpen = true">
                  </v-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalWindow
          class="article-categories"
          heading="Select categories"
          :showCancelButton="false"
          :isOpen="isCategoriesModalOpen"
          @close="isCategoriesModalOpen = false"
        >
          <template #body>
            <ul>
              <li v-for="(category, index) in modalCategories" :key="category.title">
                <div class="level is-mobile my-2">
                  <div class="level-left">
                    <a href @click.prevent="toggleSubcategoryTree(index)" role="button" class="level-item has-text-dark has-text-weight-bold">
                      <span v-show="!category.isOpen" class="icon"><i class="fas fa-plus"></i></span>
                      <span v-show="category.isOpen" class="icon"><i class="fas fa-minus"></i></span>
                      <span class="ml-2">{{ category.title }}</span>
                    </a>
                  </div>
                </div>
                <ul :class="{ 'is-hidden': !category.isOpen }">
                  <li v-for="subcategory in category.subcategories" :key="subcategory" class="ml-5">
                    <label class="checkbox ml-2">
                      <input type="checkbox" :value="{ category: category.title, subcategory }" class="mr-2" v-model="categories">
                      {{ subcategory }}
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </template>
          <template #footer>
            <button type="button" class="button is-success" @click="isCategoriesModalOpen = false">Done</button>
          </template>
        </ModalWindow>

        <hr />
        
        <!-- ROW 3 (Taxonomy) -->
        <h2 class="is-size-4 mb-4">Taxonomy</h2>
        <div v-if="!!article.taxonomies.length" class="table-container">
          <table class="table is-bordered is-fullwidth taxonomy-table">
            <thead>
              <tr>
                <th v-for="(rank, key) in taxa" :key="key" class="is-capitalized">{{ key }}</th>
                <th>Source of compound</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(taxonomy, index) in article.taxonomies" :key="`taxonomy${index}`"
                :class="{
                  'has-background-white-ter has-text-weight-bold is-italic': index === editModeRowIndex,
                  'has-text-grey-light': isTaxonomyEditMode && index !== editModeRowIndex
                }">
                <td><span :title="taxonomy.phylum">{{ taxonomy.phylum }}</span></td>
                <td><span :title="taxonomy.class">{{ taxonomy.class }}</span></td>
                <td><span :title="taxonomy.order">{{ taxonomy.order }}</span></td>
                <td><span :title="taxonomy.family">{{ taxonomy.family }}</span></td>
                <td><span :title="taxonomy.genus">{{ taxonomy.genus }}</span></td>
                <td><span :title="taxonomy.species">{{ taxonomy.species }}</span></td>
                <td>{{ taxonomy.isSourceOfCompound ? 'Yes' : '' }}</td>
                <td class="has-text-centered">
                  <button type="button" class="button is-small is-link mx-1 my-1" @click="editTaxonomyRow(index)" :disabled="isTaxonomyEditMode">
                    <span class="icon"><i class="fas fa-pen"></i></span>
                    <span>Edit</span>
                  </button>
                  <button type="button" class="button is-small is-danger mx-1 my-1" @click="deleteTaxonomyRow(index)" :disabled="isTaxonomyEditMode">
                    <span class="icon"><i class="fas fa-times"></i></span>
                    <span>Delete</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-for="(taxonomy, key) in taxa" :key="key" class="columns is-mobile mb-1">
          <div class="column is-three-fifths">
            <div class="columns is-mobile">
              <div class="column is-one-fifth">
                <label class="label is-capitalized" :for="key">{{ key }}</label>
              </div>
              <div class="column">
                <v-select
                  v-model="builderSelections[key]"
                  :ref="key"
                  :inputId="key"
                  :filterable="false"
                  :disabled="isFormLocked"
                  :options="taxonomy.options"
                  @search="debouncedGetTaxonomySuggestions"
                  @change="debouncedGetTaxonomySuggestions"
                  @search:blur="focusedTaxonomyList = ''"
                  @search:focus="focusedTaxonomyList = key">
                  <template #list-footer>
                    <li v-if="taxonomy.options.length < taxonomy.count" class="help is-italic has-text-centered pt-3 pb-2">
                      Showing {{ taxonomy.options.length }} of {{ taxonomy.count.toLocaleString() }} suggestions
                      <br />
                      Keep typing...
                    </li>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="column is-offset-1">
            <div class="columns is-mobile">
              <div class="column is-narrow">
                <label :for="`${key}New`" class="label has-text-weight-normal">Add new <span class="is-sr-only">{{ key }}</span></label>
              </div>
              <div class="column">
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <input
                      type="text"
                      class="input"
                      :id="`${key}New`"
                      @keydown.enter.prevent="addNewTaxonomicName(key)"
                      v-model.trim="newTaxonomicName[key]" />
                  </div>
                  <div class="control">
                    <button type="button" class="button is-link" @click="addNewTaxonomicName(key)" :disabled="!newTaxonomicName[key].length">Add</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column is-three-fifths">
            <div class="columns is-mobile">
              <div class="column is-offset-one-fifth">
                <div class="field has-text-centered mb-5">
                  <label class="checkbox">
                    <input type="checkbox" id="checkbox-is-source-of-compound" v-model="builderSelections.isSourceOfCompound" />
                    Is source of compound
                  </label>
                </div>
                <button
                  type="button"
                  id="btn-assign-update-taxonomy"
                  class="button is-link is-fullwidth"
                  :disabled="!isValidTaxonomyBuilder"
                  v-on="{ click: isTaxonomyEditMode ? updateTaxonomyRow : addTaxonomyRow }">
                  {{ isTaxonomyEditMode ? 'Update selected taxonomy' : 'Assign taxonomy' }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <NextPreviousButtons v-if="!isArticleReadOnly" prev="Basic" next="Compounds" :showThrobber="isFormSaving" />
      </fieldset>

    </form>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { debounce } from 'lodash';
import { MarinLitDataService } from '@/api';
import ArticleTabsMixin from '@/mixins/marinlit/article-tabs-mixin';
import categories from '@/static/marinlit/categories.json';

export default {
  name: 'TabClassification',
  mixins: [ArticleTabsMixin],
  data() {
    return {
      trivialNamesFocused: false,
      trivialNameSuggestions: [],
      modalCategories: categories,
      isCategoriesModalOpen: false,
      focusedTaxonomyList: '',
      taxa: {
        'phylum': { options: [], count: 0 },
        'class': { options: [], count: 0 },
        'order': { options: [], count: 0 },
        'family': { options: [], count: 0 },
        'genus': { options: [], count: 0 },
        'species': { options: [], count: 0 },
      },
      builderSelections: {
        'phylum': null,
        'class': null,
        'order': null,
        'family': null,
        'genus': null,
        'species': null,
        'isSourceOfCompound': false,
      },
      editModeRowIndex: null,
      newTrivialName: '',
      newTaxonomicName: { phylum: '', class: '', order: '', family: '', genus: '', species: '' },
    }
  },
  computed: {
    ...mapFields('MarinLitEditor', [
      'article.trivialNames',
      'article.categories',
    ]),
    isValidTaxonomyBuilder() {
      // At least one of the six taxonomic ranks must contain a value
      return !Object.values(this.builderSelections).every(s => s === null || s === true || s === false);
    },
    isTaxonomyEditMode() {
      return this.editModeRowIndex !== null;
    },
  },
  methods: {
    ...mapActions('MarinLitEditor', [
      'addTaxonomyRowAction',
      'updateMultiRowValueAction',
      'deleteMultiRowValueAction',
    ]),
    async getTrivialNameSuggestions(search, loading) {
      search = search || this.$refs.trivialNames.search;
      loading = loading || this.$refs.trivialNames.toggleLoading;
      loading(true);
      const result = await MarinLitDataService.getTrivialNameSuggestions(encodeURIComponent(search));
      loading(false);
      this.trivialNameSuggestions = result.Data.results ? result.Data.results : [];
    },
    async getTaxonomySuggestions(search, loading) {
      // Need to access v-select component directly for now
      // https://github.com/sagalbot/vue-select/issues/492
      const activeList = this.focusedTaxonomyList ? this.$refs[this.focusedTaxonomyList][0] : null;
      if (activeList) {
        search = search || activeList.search;
        loading = loading || activeList.toggleLoading;
        loading(true);
        const result = await MarinLitDataService.getTaxonomySuggestions(
          encodeURIComponent(activeList.inputId),
          encodeURIComponent(search.trim()));
        loading(false);
        if (result.Data) {
          this.taxa[result.Data.rank].options = result.Data.results;
          this.taxa[result.Data.rank].count = result.Data.numberOfResults;
        }
      }
    },
    debouncedGetTaxonomySuggestions: debounce(async function(search, loading) {
      await this.getTaxonomySuggestions(search, loading);
    }, 800),
    debouncedGetTrivialNameSuggestions: debounce(async function(search, loading) {
      await this.getTrivialNameSuggestions(search, loading);
    }, 800),
    addNewTaxonomicName(type) {
      this.builderSelections[type] = this.newTaxonomicName[type];
      this.newTaxonomicName[type] = '';
    },
    toggleSubcategoryTree(index) {
      this.modalCategories[index].isOpen = !this.modalCategories[index].isOpen;
    },
    addTaxonomyRow() {
      this.addTaxonomyRowAction(this.builderSelections);
      this.resetTaxonomyBuilder();
    },
    editTaxonomyRow(index) {
      if (!this.isTaxonomyEditMode) {
        this.resetTaxonomyBuilder();
        this.editModeRowIndex = index;
        // Clone it otherwise the table updates before we're ready
        this.builderSelections = {...this.article.taxonomies[index]};
      }
    },
    updateTaxonomyRow() {
      this.updateMultiRowValueAction({
        key: 'taxonomies',
        index: this.editModeRowIndex,
        value: this.builderSelections,
      });
      this.resetTaxonomyBuilder();
    },
    deleteTaxonomyRow(index) {
      this.deleteMultiRowValueAction({ key: 'taxonomies', index });
    },
    resetTaxonomyBuilder() {
      this.editModeRowIndex = null;
      this.builderSelections = {
        'phylum': null,
        'class': null,
        'order': null,
        'family': null,
        'genus': null,
        'species': null,
        'isSourceOfCompound': false,
      };
    },
  },
  watch: {
    // Fetch trivial name suggestions immediately on list focus
    async trivialNamesFocused() {
      if (this.trivialNamesFocused) {
        await this.getTrivialNameSuggestions();
      }
    },
    // Fetch taxonomy suggestions immediately on list focus
    async focusedTaxonomyList() {
      if (this.focusedTaxonomyList.length) {
        await this.getTaxonomySuggestions();
      }
    },
  },
}
</script>
