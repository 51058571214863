/* DS-2932
 *
 * This replaces https://github.com/okta/okta-vue/blob/master/src/components/LoginCallback.vue
 * in order to handle users who have authenticated successfully, but who are not authorised to 
 * use the workbench.
 * 
 * https://github.com/okta/samples-js-angular/issues/18#issuecomment-512526386 suggests
 * catching the error from handleLoginRedirect(), so that it can be displayed in the UI
 * rather than just the browser console.
 * 
 * Further attempts to access the protected areas of the workbench will try to use the same
 * Okta session, redirecting straight back to this error. So we block the UI with a modal
 * window and offer 1) a 'Log out' button to end that Okta session, or 2) the home page as
 * an alternative action which leaves the Okta session intact.
 */

<template>
  <ModalWindow :isOpen="!!errorMessage" @close="goHome">
    <template #body>
      <p id="error-message" class="is-size-5 has-text-centered mb-3">{{ errorMessage }}</p>
      <fieldset class="buttons is-centered" :disabled="busy">
        <button id="error-logout" type="button" class="button is-link" :class="{ 'is-loading': busy }" @click="logout">
          Log out
        </button>
        <button id="error-return" type="button" class="button" @click="goHome">
          Return to home page
        </button>
      </fieldset>
    </template>
  </ModalWindow>
</template>

<script>
import { ModalWindow } from '@/components/shared';
export default {
  name: 'LoginCallback',
  metaInfo: {
    title: 'Please wait...',
    titleTemplate: null,
  },
  data() {
    return {
      error: null,
      busy: false,
    }
  },
  computed: {
    errorMessage() {
      // Try to get a friendly error message from query string
      return this.$route.query.error_description || this.error;
    },
  },
  async beforeMount() {
    await this.$auth.handleLoginRedirect().catch((err) => this.error = err);
  },
  methods: {
    async logout() {
      this.busy = true;
      await this.$auth.signOut();
    },
    goHome() {
      this.$router.push({ name: 'Home' });
    },
  },
  components: {
    ModalWindow,
  },
}
</script>
