import { omit, pickBy } from 'lodash';

const getSearchPills = (state) => {
  return pickBy(state.SearchParams, v => v.label && v.value);
}

const getSearchQueryString = (state) => {
  return searchQueryStringBuilder(state.SearchParams);
}

const getSearchQueryStringWithoutStatus = (state) => {
  return searchQueryStringBuilder(omit(state.SearchParams, ['status']));
}

const searchQueryStringBuilder = (params) => {
  return Object
    .keys(pickBy(params, v => v.value))
    .map(key => `${key}=${encodeURIComponent(params[key].value)}`).join('&');
}

export {
  getSearchPills,
  getSearchQueryString,
  getSearchQueryStringWithoutStatus,
}
