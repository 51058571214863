// AUTH
export const SET_CURRENT_USER = 'setCurrentUser';
export const SET_USER_GROUPS = 'setUserGroups';

// MARINLIT IMPORTER
export const SET_ARTICLE_SERVICE_WAITING = 'setArticleServiceWaiting';
export const UPDATE_ARTICLE_SEARCH_RESULTS = 'updateArticleSearchResults';
export const DELETE_ARTICLE_SEARCH_RESULTS = 'deleteArticleSearchResults';

// MARINLIT EDITOR
export const RESET_ARTICLE_EDITOR = 'resetArticleEditor';
export const IMPORT_ARTICLE_TO_EDITOR = 'importArticleToEditor';
export const ADD_MULTI_ROW_VALUE = 'addMultiRowValue';
export const UPDATE_MULTI_ROW_VALUE = 'updateMultiRowValue';
export const REMOVE_MULTI_ROW_VALUE = 'removeMultiRowValue';
export const REMOVE_VALUE_FROM_ARRAY = 'removeValueFromArray';
