import {
  SET_CURRENT_USER,
  SET_USER_GROUPS,
} from '@/store/mutation-types';

export default {

  namespaced: true,

  // STATE
  state: {
    currentUser: null,
    currentGroups: [],
  },

  // MUTATIONS
  mutations: {
    [SET_CURRENT_USER](state, payload) {
      state.currentUser = payload.value;
    },
    [SET_USER_GROUPS](state, payload) {
      state.currentGroups = payload.value || [];
    },
  },

  // ACTIONS
  actions: {
    setCurrentUser({ commit }, value) {
      commit(SET_CURRENT_USER, { value });
    },
    setUserGroups({ commit }, value) {
      commit(SET_USER_GROUPS, { value });
    },
  },

  // GETTERS
  getters: {
    userName: state => state.currentUser ? state.currentUser.name : '',
    userGroups: state => state.currentGroups ? state.currentGroups.filter(g => g.startsWith('Workbench')) : [],
    
    // Extract and de-duplicate individual product names, eg. ['MarinLit', 'MerckIndex']
    userProducts: (state, getters) => getters.userGroups.reduce((result, group) => {
      const product = group.split(' ').length > 1 ? group.split(' ')[1] : null;
      if (product && !result.includes(product)) {
        result.push(product);
      }
      return result;
    }, []),
  },

}
