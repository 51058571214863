<template>
  <div>

    <MessageBlock v-if="saveError" heading="Failed to save article" class="is-danger">
      <p>{{ saveError }}</p>
    </MessageBlock>
    
    <fieldset :disabled="isFormLocked">

      <ArticlePreview :article="article" />
      <NextPreviousButtons v-if="!isArticleReadOnly" :showThrobber="isFormSaving">
        <template #prev>
          <button type="button" id="btn-go-back" class="button" @click="switchTab('Related')">
            <span class="icon"><i class="fas fa-backward"></i></span>
            <span>Go back</span>
          </button>
        </template>
        <template #next>
          <button type="button" class="button is-success" @click="sendForApproval" :class="{ 'is-loading': isFormSaving }">
            <span class="icon"><i class="fas fa-check"></i></span>
            <span>Send for approval</span>
          </button>
        </template>
      </NextPreviousButtons>
    </fieldset>


  </div>
</template>

<script>
import ArticleTabsMixin from '@/mixins/marinlit/article-tabs-mixin';
export default {
  name: 'TabPreview',
  mixins: [ArticleTabsMixin],
  methods: {
    async sendForApproval() {
      this.$emit('sendForApproval');
    },
  },
}
</script>
