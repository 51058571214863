<template>
  <div v-if="visible" class="modal-overlay" @click.self="close">
    <div class="modal-container">
      <p class="modal-message">{{ message }}</p>
      <div class="modal-actions">
        <button class="modal-button cancel" @click="cancel">Go back</button>
        <button class="modal-button accept" @click="confirm">Accept</button>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      default: 'Are you sure you want to commit that action?'
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      this.close();
    },
    cancel() {
      this.$emit('cancel');
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    }
  }
};
</script>
  
<style scoped>
/* Estilo del fondo grisáceo */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fondo grisáceo */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Estilo del contenedor del modal */
.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

/* Estilo del mensaje */
.modal-message {
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
}

/* Estilo de los botones */
.modal-actions {
  display: flex;
  justify-content: space-around;
}

.modal-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.modal-button.accept {
  background-color: #4CAF50;
  color: white;
}

.modal-button.cancel {
  background-color: #d1242f;
  color: white;
}
</style>
  