<template>
  <div class="progress-bar is-flex is-justify-content-center">
    <div class="is-flex">
      <div class="is-flex" v-for="status, index in statuses" :key="status">
        <div class="status-box" :class="getStatusClass(index)">
          {{ status }}
        </div>
        <div v-if="index < statuses.length - 1" class="arrow-container">
          <img v-if="index < currentIndex" src="@/styles/icons/state-arrow-done.svg" alt="state-arrow-icon" />
          <img v-else src="@/styles/icons/state-arrow-normal.svg" alt="state-arrow-icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      allStatus: ['Processing', 'Awaiting mapping', 'Awaiting approval', 'Publishing', 'Published'],
    }
  },
  computed: {
    statuses() {
      if (this.currentStatus === 'Rejected') return ['Processing', 'Awaiting mapping', 'Awaiting approval', 'Rejected'];
      if (this.currentStatus === 'Failed') return ['Processing', 'Awaiting mapping', 'Awaiting approval', 'Publishing', 'Failed'];
      return this.allStatus;
    },
    currentIndex() {
      return this.statuses.indexOf(this.currentStatus);
    },
  },
  methods: {
    getStatusClass(index) {
      if (index < this.currentIndex) {
        return 'has-background-custom-grey has-text-white';
      } else if (index === this.currentIndex) {
        return 'has-background-black has-text-white';
      } else {
        return 'has-background-white has-text-black';
      }
    }
  }
}
</script>


<style scoped>
.progress-bar {
  margin: 67px 0px;
}

.progress-bar .status-box {
  text-align: center;
  padding: 13px 24px;
  border: 1px solid black;
  border-right: 1px solid black;
  border-radius: 4px;
  color: black;
}


.progress-bar .status-box.active {
  background-color: black;
  color: white;
}

.arrow-container {
  display: flex;
}
</style>