<template>
  <div>
    <ArticleInfo :article="article" />
    <hr />

    <MessageBlock v-if="compoundEditError" heading="Error" class="is-danger">
      <p>There was a problem fetching that compound: {{ compoundEditError }}</p>
    </MessageBlock>

    <MessageBlock v-if="compoundDeleteError" heading="Error" class="is-danger">
      <p>There was a problem deleting that compound: {{ compoundDeleteError }}</p>
    </MessageBlock>
    
    <button v-if="!isArticleReadOnly" type="button" id="btn-add-compound" class="button is-link" :disabled="isFormLocked" @click="addCompound">
      <span class="icon"><i class="fas fa-plus"></i></span>
      <span>Add compound</span>
    </button>

    <div v-if="article.substances.length" class="columns is-mobile is-multiline mt-3">
      <div v-for="recordId in article.substances" :key="recordId" class="column is-half">
        <CompoundCard
          :recordId="recordId"
          :showButtons="true"
          :isArticleReadOnly="isArticleReadOnly"
          @edit="editCompound(recordId)"
          @delete="deleteCompound(recordId)"
          @emit-locations="updateCompoundLocationsForCopying"
        />
      </div>
    </div>
    <div v-else-if="isArticleReadOnly">
      This article has no compounds.
    </div>

    <NextPreviousButtons v-if="!isArticleReadOnly" :showThrobber="isFormSaving">
      <template #prev>
        <button type="button" id="btn-go-back" class="button" :disabled="isFormLocked" @click="switchTab('Classification')">
          <span class="icon"><i class="fas fa-backward"></i></span>
          <span>Go back</span>
        </button>
      </template>
      <template #next>
        <button type="button" id="btn-continue" class="button is-success" :disabled="isFormLocked" @click="switchTab('Related')">
          <span>Continue</span>
          <span class="icon"><i class="fas fa-forward"></i></span>
        </button>
      </template>
    </NextPreviousButtons>

    <!-- Uses v-if because the modal must be removed from the DOM after -->
    <!-- each compound edit to prevent conflicting instances of TinyMCE -->
    <div v-if="isCompoundEditorOpen" class="modal compound-editor is-active">
      <div class="modal-background"></div>
      <ValidationObserver tag="div" ref="compoundEditor" v-slot="{ handleSubmit, failed, errors }">

        <form class="modal-card" @submit.prevent="handleSubmit(preSubmissionCheck)">
        
          <header class="modal-card-head">
            <p class="modal-card-title">{{ compoundEditorHeading }}</p>
          </header>
          
          <section class="modal-card-body">
          
            <fieldset :disabled="isCompoundEditorLocked">

              <!-- ROW 1 (Compound name and status) -->
              <h3 class="is-size-5 mb-2">Compound details</h3>
              <div class="columns is-mobile">
                <div class="column is-two-thirds">
                  <ValidationProvider rules="html_required:title" tag="div" class="field" v-slot="{ errors }">
                    <label class="label">Compound name<RequiredAsterisk /></label>
                    <div class="control" :class="{ 'is-danger': !!errors.length }">
                      <HtmlEditor id="title" :apiKey="TinyMceApiKey" cloud-channel="5" :init="TinyMceConfig" :disabled="isCompoundEditorLocked" v-model="compoundEditor.title" />
                      <p v-if="!!errors.length" class="help is-danger">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="column is-one-third">
                  <SelectElement id="status" rules="required" label="Compound status" :options="compoundStatuses" v-model="compoundEditor.status" />
                </div>
              </div>

              <hr />
              
              <!-- ROW 2 (Location information) -->
              <h3 class="is-size-5 mb-2">Location information</h3>
              <div v-for="(loc, index) in compoundEditor.locations" :key="`locations${index}`">
                <div v-if="index === 0" class="columns is-mobile mb-0">
                  <label for="locName0" class="label column is-3 pb-0">Location name/site<RequiredAsterisk /></label>
                  <label for="locLat0" class="label column is-2 pb-0">Latitude<RequiredAsterisk /></label>
                  <label for="locLng0" class="label column is-2 pb-0">Longitude<RequiredAsterisk /></label>
                  <label for="locDepth0" class="label column is-2 pb-0">Depth (m)</label>
                  <label for="locQf0" class="label column is-3 pb-0">Location QF<RequiredAsterisk /></label>
                </div>
                <div class="columns is-mobile mb-0">
                  <TextInput :id="`locName${index}`" class="column is-3" rules="required" placeholder="Location" v-model="loc.name" />
                  <TextInput :id="`locLat${index}`" class="column is-2" rules="required|number|min_max:-90,90" placeholder="Latitude" v-model.number="loc.location.lat" />
                  <TextInput :id="`locLng${index}`" class="column is-2" rules="required|number|min_max:-180,180" placeholder="Longitude" v-model.number="loc.location.lon" />
                  <TextInput :id="`locDepth${index}`" class="column is-2" rules="number|non_negative" placeholder="Depth" v-model.trim="loc.depth" />
                  <SelectElement :id="`locQf${index}`" class="column is-narrow mb-0" rules="required" :options="compoundLocationQfOptions" v-model.number="loc.qf" />
                  <div class="column">
                    <button type="button" class="button is-fullwidth is-danger" @click="deleteLocation(index)">
                      <span class="icon"><i class="fas fa-trash-alt"></i></span>
                      <span>Remove</span>
                    </button>
                  </div>
                </div>
              </div>
              <button type="button" class="button is-link is-outlined my-3" @click="addLocation">
                Add {{ compoundEditor.locations.length > 0 ? 'another' : '' }} location
              </button>
              <div v-if="isCopyLocationsDropdownVisible" class="dropdown is-active mx-3 my-3">
                <div class="dropdown-trigger">
                  <button type="button" class="button" @click="toggleCopyLocationsDropdownVisibility" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span>Copy locations from another compound</span>
                    <span class="icon has-text-link"><i class="fas fa-chevron-down"></i></span>
                  </button>
                </div>
                <div v-if="isCopyLocationsDropdownOpen" class="dropdown-menu" id="dropdown-menu" role="menu">
                  <div class="dropdown-content">
                    <div v-for="(compound, index) in otherCompoundsWithLocations" :key="`compounds${index}`">
                      <a href @click.prevent="copyLocations(compound.locations)"
                        v-html="compound.title"
                        class="dropdown-item has-text-weight-bold">
                      </a>
                      <a v-for="(loc, index) in compound.locations" :key="`loc${index}`"
                        href @click.prevent="copyLocations([loc])"
                        class="dropdown-item">
                        <span class="ml-4">
                          {{ loc.name }} ({{ loc.location.lat }}, {{ loc.location.lon }})
                        </span>
                      </a>
                      <hr v-if="index < otherCompoundsWithLocations.length - 1" class="dropdown-divider">
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              
              <!-- ROW 3 (PNG, MOL and CDX file upload/download) -->
              <h3 class="is-size-5 mb-2">Compound data</h3>
              <div class="columns is-mobile mb-0">
                <!-- Removed :isActive="true" at the end of each FileInput to allow trash bin to appear -->
                <FileInput class="column is-one-third" id="pngFilePath" label="Image" fileType="png" :fileSizeLimit="512 * 1024" :filePath="compoundEditor.pngFilePath" @update="updateCompoundFile"/>
                <FileInput class="column is-one-third" id="molFilePath" label="MOL" fileType="mol" :fileSizeLimit="512 * 1024" :filePath="compoundEditor.molFilePath" @update="updateCompoundFile" />
                <FileInput class="column is-one-third" id="cdxFilePath" label="CDX" fileType="cdx" :fileSizeLimit="512 * 1024" :filePath="compoundEditor.cdxFilePath" @update="updateCompoundFile" />
                <!--  -->
              </div>

              <!-- ROW 4 (Molecular formula, exact mass and compound number) -->
              <div class="columns is-mobile mb-0">
                <div class="column is-two-thirds field mb-0">
                  <label class="label">Molecular formula</label>
                  <div class="control">
                    <HtmlEditor id="molecularFormula" :apiKey="TinyMceApiKey" cloud-channel="5" :init="TinyMceConfig" :disabled="isCompoundEditorLocked" v-model="compoundEditor.molecularFormula" />
                  </div>
                </div>
                <TextInput class="column" id="molecularWeight" rules="number|non_negative" label="Exact mass" v-model.trim="compoundEditor.molecularWeight" />
                <TextInput class="column" id="note" label="Compound number" v-model.trim="compoundEditor.note" />
              </div>

              <!-- ROW 5 (Standard InChI and InChIKey) -->
              <div class="columns is-mobile mb-0">
                <TextArea class="column is-two-thirds mb-0" id="inchi" rules="inchi" label="Standard InChI" v-model.trim="compoundEditor.inchi" />
                <TextInput class="column is-one-third" id="inchiKey" rules="inchikey" label="InChIKey" v-model.trim="compoundEditor.inchiKey" />
              </div>

              <hr />

              <!-- ROW 6 (UV) -->
              <h3 class="is-size-5 mb-2">UV</h3>
              <div v-for="(solvent, solventIndex) in compoundEditor.properties" :key="`properties${solventIndex}`">
                <div class="columns is-mobile mb-0">
                  <label class="label column is-4 pb-0">Solvent</label>
                  <div class="column is-5 pb-0">
                    <div class="columns is-mobile mb-0">
                      <label class="label column is-4 pb-0" :for="`uvMaxValue${solventIndex}.0`">UV value (nm)<RequiredAsterisk /></label>
                      <label class="label column is-4 pb-0" :for="`uvMaxLogEValue${solventIndex}.0`">Log Ɛ</label>
                    </div>
                  </div>
                </div>
                <div class="columns is-mobile mb-0">
                  <div class="column is-4">
                    <HtmlEditor :apiKey="TinyMceApiKey" cloud-channel="5" :init="TinyMceConfig" :disabled="isCompoundEditorLocked" v-model="solvent.name" />
                  </div>
                  <div class="column is-5">
                    <div class="columns is-mobile mb-0" v-for="(uvMax, index) in solvent.details" :key="`details${index}`">
                      <TextInput class="column is-4" :id="`uvMaxValue${solventIndex}.${index}`" rules="required|number|non_negative" v-model.trim="uvMax.value" />
                      <TextInput class="column is-4" :id="`uvMaxLogEValue${solventIndex}.${index}`" rules="number|non_negative" v-model.trim="uvMax.logEValue" />
                      <div class="column is-4">
                        <button v-if="index === 0" type="button" class="button is-link is-outlined is-fullwidth" @click="addUvMaxRow(solventIndex)">
                          <span class="icon"><i class="fas fa-plus"></i></span>
                          <span>Add row</span>
                        </button>
                        <button v-else type="button" class="button is-outlined is-fullwidth" @click="removeUvMaxRow(solventIndex, index)">
                          <span class="icon mr-2"><i class="fas fa-trash-alt"></i></span>
                          <span>Remove</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="column is-3 px-2">
                    <button type="button" class="button is-danger is-pulled-right" @click="deleteSolvent(solventIndex)">
                      <span class="icon"><i class="fas fa-trash-alt"></i></span>
                      <span>Remove solvent</span>
                    </button>
                  </div>
                </div>
              </div>
              <button type="button" class="button is-link is-outlined mt-3 mb-5" @click="addSolvent">
                Add {{ compoundEditor.properties.length > 0 ? 'another' : '' }} solvent
              </button>

              <!-- Store server-side errors here -->
              <ValidationProvider vid="saveError" />

            </fieldset>

          </section>

          <footer class="modal-card-foot">
            <button v-if="!isArticleReadOnly" type="submit" class="button is-success footer-buttons-015" :class="{ 'is-loading': isCompoundEditorSaving }">
              {{ isNewCompoundEntry ? 'Save compound' : 'Save changes' }}
            </button>
            <button type="button" class="button footer-buttons-015" @click="resetCompoundEditor" :class="{ 'is-loading': isCompoundEditorSaving }">
              {{ isArticleReadOnly ? 'Close' : 'Cancel' }}
            </button>
            <span v-if="errors.saveError && errors.saveError.length" class="has-text-danger has-text-weight-bold">
              Failed to save compound: {{ errors.saveError[0] }}
            </span>
            <span v-else-if="failed" class="has-text-danger has-text-weight-bold">
              There are errors on the form. Please fix them before continuing.
            </span>
          </footer>

        </form>

      </ValidationObserver>
      
    </div>

    <!-- Duplicate InChIKey warning -->
    <ModalWindow
      :isOpen="isDuplicateCompoundWarningVisible"
      :showCancelButton="false"
      @close="hideDuplicateCompoundWarning(false)"
    >
      <template #body>
        <div class="content has-text-centered has-text-weight-bold">
          <p v-if="hasDuplicateCompoundsRequestError">
            Sorry, the InChIKey duplication check didn't work
          </p>
          <p v-else>
            That InChIKey is already used by {{ duplicateCompounds.join(', ') }}
          </p>
          <button type="button" class="button is-danger mr-2" @click="hideDuplicateCompoundWarning(true)">
            Save anyway
          </button>
          <button type="button" class="button" @click="hideDuplicateCompoundWarning(false)">
            Keep editing
          </button>
        </div>
      </template>
    </ModalWindow>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { cloneDeep, merge, remove } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { MarinLitDataService } from '@/api';
import { FileInput } from '@/components/forms';
import ArticleTabsMixin from '@/mixins/marinlit/article-tabs-mixin';

export default {
  name: 'TabCompounds',
  mixins: [ArticleTabsMixin],
  data() {
    return {
      // Compound list
      compoundEditError: null,
      compoundDeleteError: null,
      
      // Compound editor
      compoundEditor: {},
      compoundEditorFiles: { 'png': null, 'mol': null, 'cdx': null },
      isCompoundEditorOpen: false,
      isCompoundEditorSaving: false,
      isCopyLocationsDropdownOpen: false,
      compoundLocationsForCopying: [],
      compoundStatuses: [
        'New',
        'New to marine',
        'Revision',
        'New and revised in a subsequent article',
        'New to marine and revised in a subsequent article',
        'Revision and revised in a subsequent article'
      ],
      compoundLocationQfOptions: ['1', '2', '3'],
      TinyMceConfig: {
        height: 100, // Override default height
      },

      // Duplicate InChIKey warning
      duplicateCompounds: [],
      isDuplicateCompoundWarningVisible: false,
      hasDuplicateCompoundsRequestError: false,
    }
  },  
  // To set an event listener of confirm method to delete (png, cdx, mol) file
  mounted(){
    this.$root.$on("confirm", (type) => {
      return this.confirm(type);
    });
  },
  // remove event listener of confirm method
  beforeDestroy() {
   this.$root.$off('confirm');
  },  
  computed: {
    compoundEditorHeading() {
      return this.isNewCompoundEntry
        ? 'Add compound'
        : `${!this.isArticleReadOnly ? 'Edit' : ''} ${this.compoundEditor.marinLitId} ${this.isArticleReadOnly ? '(read-only)' : ''}`;
    },
    isNewCompoundEntry() {
      return !this.compoundEditor.marinLitId;
    },
    isCompoundEditorLocked() {
      return this.isCompoundEditorSaving || this.isArticleReadOnly;
    },
    isCopyLocationsDropdownVisible() {
      return !!this.otherCompoundsWithLocations.length;
    },
    otherCompoundsWithLocations() {
      return this.compoundLocationsForCopying.filter(c => c.locations.length && c.marinLitId !== this.compoundEditor.marinLitId);
    },
  },
  methods: {
    ...mapActions('MarinLitEditor', [
      'removeValueFromArrayAction',
    ]),
    
    // COMPOUND LOADING AND SAVING
    async editCompound(recordId) {
      this.resetCompoundListErrors();
      const result = await MarinLitDataService.getCompound(recordId);
      if (result.Data) {
        this.compoundEditor = result.Data;
        this.compoundEditorFiles = this.getEmptyCompoundFiles();
        this.isCompoundEditorOpen = true;
      }
      else {
        this.compoundEditError = result.ServiceStatus.Error;
      }
    },
    async deleteCompound(recordId) {
      this.resetCompoundListErrors();
      const answer = window.confirm('Do you really want to delete this compound?\nThis action is permanent and irreversible.');
      if (answer) {
        const result = await MarinLitDataService.deleteCompound(recordId);
        if (result.Data && result.Data.ok) {
          this.$emit('getArticle', this.article.articleId);
          remove(this.compoundLocationsForCopying, c => c.marinLitId === result.Data.marinLitId);
        }
        else {
          this.handleCompoundDeleteError(result.ServiceStatus.Error);
        }
      }
    },
    async preSubmissionCheck() {
      this.duplicateCompounds = await this.getDuplicateCompounds();
      if (this.duplicateCompounds.length || this.hasDuplicateCompoundsRequestError) {
        this.showDuplicateCompoundWarning();
      }
      else {
        await this.submitCompound();
      }
    },
    async submitCompound() {
      if (this.isCompoundEditorSaving === false) {
        this.isCompoundEditorSaving = true;
        
        // New compounds must provide their own new ID and their article ID
        if (this.isNewCompoundEntry) {
          this.compoundEditor.article = this.article.articleId;
        }

        // Prepare the compound data and any files
        const compoundData = JSON.stringify(this.compoundEditor);
        let formData = new FormData();

        formData.append('compound', compoundData);
        if (this.compoundEditorFiles.png) formData.append('pngFile', this.compoundEditorFiles.png);
        if (this.compoundEditorFiles.mol) formData.append('molFile', this.compoundEditorFiles.mol);
        if (this.compoundEditorFiles.cdx) formData.append('cdxFile', this.compoundEditorFiles.cdx);
        
        const result = await MarinLitDataService.addOrUpdateCompound(formData);
        if (result.Data && result.Data.recordId) {
          
          // Force an existing compound card to update itself by removing it from the 
          // article editor before refreshing the tab contents.
          this.removeValueFromArrayAction({ key: 'substances', value: result.Data.recordId });
          
          // The compound is added to the article by the same API call so we only 
          // need to refresh the tab contents behind the compound editor to show
          // a new card for the new compound.
          this.$emit('getArticle', this.article.articleId);
          this.resetCompoundEditor();
        }
        else {
          this.isCompoundEditorSaving = false;
          this.handleCompoundSubmissionError(result.ServiceStatus.Error);
        }
      }
    },
    
    // ERROR HANDLING
    resetCompoundListErrors() {
      this.compoundEditError = null;
      this.compoundDeleteError = null;
    },
    handleCompoundDeleteError(error) {
      this.compoundDeleteError = error.statusText ? error.statusText : error.errorMessage;
    },
    handleCompoundSubmissionError(error) {
      const message = error.statusText ? error.statusText : error.errorMessage;
      this.$refs.compoundEditor.setErrors({ saveError: [message] });
    },

    // MULTI-ROW COMPOUND DATA (LOCATIONS / UV MAX)
    addLocation() {
      this.compoundEditor.locations.push({ name: '', location: { lat: '', lon: '' }, depth: '', qf: '' });
    },
    copyLocations(locations) {
      // Deep clone to prevent cross-field reactivity
      this.compoundEditor.locations.push(...cloneDeep(locations));
      this.isCopyLocationsDropdownOpen = false;
    },
    deleteLocation(index) {
      this.compoundEditor.locations.splice(index, 1);
    },
    updateCompoundLocationsForCopying(compound) {
      // Do we already know about this compound?
      const index = this.compoundLocationsForCopying.findIndex(c => c.marinLitId === compound.marinLitId);
      if (index !== -1) {
        this.$set(this.compoundLocationsForCopying, index, compound);
      } else {
        this.compoundLocationsForCopying.push(compound);
      }
    },
    toggleCopyLocationsDropdownVisibility() {
      this.isCopyLocationsDropdownOpen = !this.isCopyLocationsDropdownOpen;
    },
    addSolvent() {
      this.compoundEditor.properties.push({ name: '', details: [{ logEValue: '', value: '' }] });
    },
    deleteSolvent(solventIndex) {
      this.compoundEditor.properties.splice(solventIndex, 1);
    },
    addUvMaxRow(solventIndex) {
      this.compoundEditor.properties[solventIndex].details.push({ logEValue: '', value: '' });
    },
    removeUvMaxRow(solventIndex, index) {
      this.compoundEditor.properties[solventIndex].details.splice(index, 1);
    },

    // COMPOUND FILE HANDLING
    async updateCompoundFile(type, file, molFileData) {
      if (molFileData) {
        // Overwrite inchi, inchiKey, molecularFormula and molecularWeight
        merge(this.compoundEditor, molFileData);
      }
      this.compoundEditorFiles[type] = file;
    },

    // INCHIKEY DUPLICATION CHECK
    async getDuplicateCompounds() {
      // Don't make API request without an InChIKey
      if (this.compoundEditor.inchiKey) {
        const result = await MarinLitDataService.getCompoundsByInchiKey(this.compoundEditor.inchiKey, this.compoundEditor.recordId);
        if (result.Data) {
          return result.Data.results.map(obj => `(${obj.marinLitId})`);
        }
        else {
          this.hasDuplicateCompoundsRequestError = result.ServiceStatus.HasError;
        }
      }
      return [];
    },
    showDuplicateCompoundWarning() {
      this.isDuplicateCompoundWarningVisible = true;
    },
    async hideDuplicateCompoundWarning(saveAnyway) {
      this.duplicateCompounds = [];
      this.isDuplicateCompoundWarningVisible = false;
      this.hasDuplicateCompoundsRequestError = false;
      if (saveAnyway) {
        await this.submitCompound();
      }
    },

    // COMPOUND EDITOR MANAGEMENT
    addCompound() {
      this.compoundEditor = this.getEmptyCompound();
      this.compoundEditorFiles = this.getEmptyCompoundFiles();
      this.isCompoundEditorOpen = true;
    },
    resetCompoundEditor() {
      this.isCompoundEditorOpen = false;
      this.isCompoundEditorSaving = false;
      this.isCopyLocationsDropdownOpen = false;
      this.compoundEditor = this.getEmptyCompound();
      this.compoundEditorFiles = this.getEmptyCompoundFiles();
    },
    getEmptyCompound() {
      return {
        recordId: uuidv4(),
        marinLitId: '',
        title: '',
        status: '',
        locations: [],
        molecularFormula: '',
        molecularWeight: '',
        note: '',
        inchi: '',
        inchiKey: '',
        properties: [],
      };
    },
    getEmptyCompoundFiles() {
      return {
        'png': null,
        'mol': null,
        'cdx': null,
      };
    },
    async confirm(type) {
      await this.$confirm(`Are you sure you want delete the ${type} file?`).then(() => {
        this.compoundEditorFiles[type] = null;
        this.compoundEditor[`${type}FilePath`] = null;
      }).catch(()=> console.log(`the cancel button was clicked`));
    },
  },
  components: {
    FileInput,
  },
}
</script>
