import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TextArea, TextInput, SelectElement, RequiredAsterisk } from '@/components/forms';
import { ArticleInfo, NextPreviousButtons } from '@/components/marinlit/edit';
import { ArticleCard, CompoundCard, ArticlePreview } from '@/components/marinlit/shared';
import { MessageBlock, ModalWindow } from '@/components/shared';
import HtmlEditor from '@tinymce/tinymce-vue';
import HtmlEditorConfig from '@/mixins/shared/html-editor-config';

export default {
  mixins: [HtmlEditorConfig],
  async mounted() {
    // Every time a tab panel is shown, fetch the latest 
    // version of the article specified in the URL.
    if (this.$route.params.id) {
      this.$emit('getArticle', this.$route.params.id);
    }
  },
  props: {
    isFormLocked: {
      type: Boolean,
      default: false,
    },
    isFormSaving: {
      type: Boolean,
      default: false,
    },
    isArticleReadOnly: {
      type: Boolean,
      default: false,
    },
    formObserver: {
      type: String,
      default: '',
      validator: value => ['Basic', 'Classification', 'Compounds', 'Related', 'Preview'].includes(value),
    },
    validationErrors: {
      type: Object,
      required: false,
    },
    saveError: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    ...mapState('MarinLitEditor', {
      article: state => state.article,
    }),
  },
  methods: {
    switchTab(tab) {
      this.$emit('switchTab', tab);
    },
    async saveArticle(e) {
      // e.submitter.value is the name of the tab to switch to,
      // assuming that changes are saved successfully. It's not
      // supported in IE or Edge Legacy.
      this.$emit('saveArticle', e.submitter ? e.submitter.value : null);
    },
  },
  watch: {
    validationErrors(errors) {
      // For now, only the 'Basic info' tab requires server-side validation
      // https://logaretm.github.io/vee-validate/advanced/server-side-validation.html
      const form = this.$refs[this.formObserver];
      if (form && errors) {
        form.setErrors(errors);
      }
    },
  },
  components: {
    ArticleCard,
    ArticleInfo,
    ArticlePreview,
    CompoundCard,
    HtmlEditor,
    MessageBlock,
    ModalWindow,
    NextPreviousButtons,
    RequiredAsterisk,
    SelectElement,
    TextArea,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
};
