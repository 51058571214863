import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VueMeta from 'vue-meta';
import vSelect from 'vue-select';
import OpenIndicator from '@/components/forms/vselect-open-indicator.vue';
import VueSimpleAlert from "vue-simple-alert";
import vClickOutside from 'v-click-outside';
import '@/validation-rules';

// See https://github.com/FortAwesome/vue-fontawesome#usage
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faBackward, faCheck, faChevronDown, faChevronUp, faDownload, faExternalLinkAlt, faFile, faForward,
  faKey, faMinus, faPen, faPlus, faArrowDown, faArrowUp, faArrowDownWideShort, faTimes, faTrashAlt,
  faUndo, faUpload, faBars, faTrash, faCopy
} from '@fortawesome/free-solid-svg-icons';
library.add(faBackward, faCheck, faChevronDown, faChevronUp, faDownload, faExternalLinkAlt, faFile, faForward,
  faKey, faMinus, faPen, faPlus, faArrowDown, faArrowUp, faArrowDownWideShort, faTimes, faTrashAlt,
  faUndo, faUpload, faBars, faTrash, faCopy);

// See https://github.com/FortAwesome/vue-fontawesome#processing-i-tags-into-svg-using-font-awesome
dom.watch();

Vue.use(VueMeta);
Vue.use(vClickOutside);

vSelect.props.components.default = () => ({
  OpenIndicator,
  Deselect: { render: createElement => createElement('span', { class: 'delete is-medium', }) },
});
Vue.component('v-select', vSelect);
Vue.use(VueSimpleAlert);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
