<template>
  <span>{{ formattedAuthors }}</span>
</template>

<script>
  export default {
    name: 'ArticleAuthors',
    props: {
      authors: {
        type: Array,
        default: () => []
      },
    },
    computed: {
      formattedAuthors() {
        const concatenated = this.authors.map(a => `${a.firstName} ${a.lastName}`);
        return concatenated.join(', ');
      },
    },
  }
</script>
