<template>
  <section class="add-datasource">
    <AddEditDatasource />
  </section>
</template>

<script>

import AddEditDatasource from '@/components/cs-depositions/shared/addEditDatasource.vue'


export default {
  name: 'addDataSource',
  components: {
    AddEditDatasource,
  },
};
</script>
