<template>
  <div class="copy-button-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
    <transition name="fade" mode="out-in">
      <span :key="isCopied" class="icon" :class="isCopied ? 'check' : 'clipboard'" @click="copyText">
        <font-awesome-icon :icon="isCopied ? 'check' : 'copy'" />
      </span>
    </transition>
    <span v-if="tooltipVisible" :class="['tooltip', { copied: isCopied }]">
      {{ tooltipText }}
    </span>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    text: {
      type: [String, undefined],
    },
  },
  data() {
    return {
      tooltipVisible: false,
      tooltipText: 'Copy to clipboard',
      isCopied: false
    };
  },
  methods: {
    async copyText() {
      try {
        await navigator.clipboard.writeText(this.text);
        this.isCopied = true;
        this.tooltipText = 'Copied to clipboard!';
        this.$emit('copied', true);

        setTimeout(() => {
          this.isCopied = false;
          this.tooltipText = 'Copy to clipboard';
        }, 4000);
      } catch (err) {
        console.error('Unable to copy text:', err);
        this.$emit('copied', false);
      }
    },
    showTooltip() {
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
      if (!this.isCopied) {
        this.tooltipText = 'Copy to clipboard';
      }
    }
  }
};
</script>

<style scoped>
.copy-button-container {
  position: relative;
  display: inline-block;
}

.icon {
  cursor: pointer;
}

.icon.clipboard {
  color: #000000;
}

.icon.check {
  color: #4CAF50;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s ease, visibility 0.1s ease;
}

.tooltip.copied {
  background-color: #4CAF50;
}

.copy-button-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease, transform 0.1s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
</style>
