<template>
  <div>
    <ArticleInfo :article="article" />
    <hr />

    <MessageBlock v-if="saveError" heading="Failed to save article" class="is-danger">
      <p>{{ saveError }}</p>
    </MessageBlock>

    <ValidationObserver tag="div" :ref="formObserver">
    
      <form @submit.prevent="saveArticle">

        <fieldset :disabled="isFormLocked">

          <!-- ROW 1 (Add related article) -->
          <div class="columns is-mobile">
            <div class="column is-two-fifths">
              <TextInput
                customKeydownEnter
                rules="articleId"
                id="searchArticleId"
                label="Add related article"
                placeholder="e.g. A23744"
                @keydown-enter="getArticleById"
                v-model.trim="searchArticleId">
                <template #addons>
                  <div class="control">
                    <button type="button" class="button is-link" @click="getArticleById">Add</button>
                  </div>
                </template>
              </TextInput>
            </div>
          </div>
          
          <!-- ROW 2 (Related articles) -->
          <ArticleCard
            v-for="articleId in article.relatedArticles"
            :key="articleId"
            :articleId="articleId"
            :showButtons="true"
            @remove="removeRelatedArticle(articleId)"
          />

          <!-- ROW 3 (Note) -->
          <div class="field">
            <label class="label">Note</label>
            <div class="control">
              <HtmlEditor id="related-articles-note" :apiKey="TinyMceApiKey" cloud-channel="5" :init="TinyMceConfig" v-model="note" />
            </div>
          </div>
          <NextPreviousButtons v-if="!isArticleReadOnly" prev="Compounds" next="Preview" :showThrobber="isFormSaving" />
        </fieldset>

      </form>

    </ValidationObserver>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { MarinLitDataService } from '@/api';
import ArticleTabsMixin from '@/mixins/marinlit/article-tabs-mixin';

export default {
  name: 'TabRelated',
  mixins: [ArticleTabsMixin],
  data() {
    return {
      searchArticleId: '',
    }
  },
  computed: {
    ...mapFields('MarinLitEditor', [
      'article.relatedArticles',
      'article.note',
    ]),
  },
  methods: {
    ...mapActions('MarinLitEditor', [
      'addMultiRowValueAction',
      'removeValueFromArrayAction',
    ]),
    addRelatedArticle(articleId) {
      this.addMultiRowValueAction({ key: 'relatedArticles', value: articleId });
    },
    removeRelatedArticle(articleId) {
      this.removeValueFromArrayAction({ key: 'relatedArticles', value: articleId });
    },
    async getArticleById() {
      if (this.searchArticleId) {
        // Revalidate when 'Add' button is clicked
        const validator = await this.$refs[this.formObserver].refs.searchArticleId.validate();
        if (validator.valid) {
          // Find the requested article using the search results API
          const result = await MarinLitDataService.getSearchResults(`marinLitId=${this.searchArticleId}`);
          if (result.Data.searchResults) {
            // Check that it's not a duplicate etc.
            const articleId = this.validateRelatedArticle(result.Data.searchResults);
            if (articleId) {
              this.addRelatedArticle(articleId);
              this.searchArticleId = '';
            }
          }
          // API error
          else {
            this.$refs[this.formObserver].setErrors({
              searchArticleId: [result.ServiceStatus.Error]
            });
          }
        }
      }
    },
    validateRelatedArticle(results) {
      let error = '';
      if (!results.length) {
        error = `${this.searchArticleId} doesn't exist or was discarded`;
      }
      else {
        const article = results[0];
        if (article.articleId === this.article.articleId) {
          error = `${article.marinLitId} can't be added to itself`;
        }
        else if (this.relatedArticles.includes(article.articleId)) {
          error = `${article.marinLitId} was already added to this article`;
        }
        // Passed validation, so return the ID of the related article
        else {
          return article.articleId;
        }
      }
      // Failed validation, so set errors and return nothing
      this.$refs[this.formObserver].setErrors({ searchArticleId: [error] });
      return null;
    }
  },
}
</script>
