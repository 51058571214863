<template>
  <section class="add-edit-datasource">
    <DepositionsHeader :title="datasource.Name ? `Data source: ${datasource.Name}` : datasource.CompanyName ? `Data source: ${datasource.CompanyName}` : ''" />
    <div class="company-basics">
      <!-- Company logo -->
      <div class="is-flex is-flex-direction-column">
        <div class="has-text-weight-bold input-title">Company logo:</div>
        <file-pond
          class="file-pond"
          name="test"
          ref="pond"
          label-idle="Drop files here..."
          v-bind:allow-multiple="false"
          accepted-file-types="image/jpeg, image/png"
          :server="localServer"
          v-bind:files="files"
          v-on:init="filePondInit = true"
          v-on:processfile="handleFilePondProcessfile"
          v-on:removefile="handleFilePondRemovefile"
          :credits="'false'"
          :labelIdle="`Drag here the data source logo or <span class='filepond--label-action'> Browse </span>`"
          :labelFileProcessing="`Processing`"
          :labelFileProcessingComplete="`Processing complete`"
        />
      </div>

      <!-- Company Name, cannot be edited -->
      <div class="is-flex is-flex-direction-column company-name" v-if="datasource.CompanyName || datasource.Name || !isEditPage">
        <div class="has-text-weight-bold input-title">Company name:</div>
        <div class="has-background-white" v-if="!isEditPage">
          <input type="text" class="company-name-input" v-model="datasource.CompanyName" />
        </div>
        <div v-if="isEditPage">
          <p>{{ datasource.CompanyName ?? datasource.Name }}</p>
        </div>
      </div>

      <!-- Description -->
      <div class="is-flex is-flex-direction-column">
        <div class="has-text-weight-bold input-title">Description:</div>
        <div class="has-background-white">
          <textarea class="description-input" placeholder="Add description" v-model="datasource.Description"></textarea>
        </div>
      </div>
    </div>
    <div class="company-contacts1">
      <!-- StreetAddress -->
      <div class="is-flex is-flex-direction-column">
        <div class="input-title">Street:</div>
        <div>
          <input type="text" class="company-contacts-street" v-model="datasource.StreetAddress" />
        </div>
      </div>

      <!-- City -->
      <div class="is-flex is-flex-direction-column">
        <div class="input-title">City:</div>
        <div>
          <input type="text" class="company-contacts-city" v-model="datasource.City" />
        </div>
      </div>

      <!-- ZipCode -->
      <div class="is-flex is-flex-direction-column">
        <div class="input-title">Postcode:</div>
        <div>
          <input type="text" class="company-contacts-postcode" v-model="datasource.ZipCode" />
        </div>
      </div>
    </div>

    <div class="company-contacts2">
      <!-- Phone Number -->
      <div class="is-flex is-flex-direction-column">
        <div class="input-title">Phone number:</div>
        <div class="is-flex">
          <div>
            <select v-model="selectedPrefix" id="country-prefix" @change="updatePhoneWithPrefix" class="phone-number-prefix mr-2">
              <option v-for="prefix in countryPrefixes" :key="prefix.code" :value="prefix.dial_code">
                {{ prefix.code }}
              </option>
            </select>
          </div>
          <div>
            <input type="text" class="phone-number" v-model="datasource.Phone" />
          </div>
        </div>
      </div>

      <!-- E-mail -->
      <div class="is-flex is-flex-direction-column">
        <div class="input-title">Email address:</div>
        <div>
          <input type="text" class="company-contacts-email" v-model="datasource.Email" />
        </div>
      </div>

      <!-- Website -->
      <div class="is-flex is-flex-direction-column">
        <div class="input-title">Website URL:</div>
        <div>
          <input type="text" class="company-contacts-website" v-model="datasource.Website" />
        </div>
      </div>

      <div class="is-flex is-flex-direction-column">
        <div class="input-title">Classification:</div>
        <div class="checkbox-list" v-if="!isEditPage">
          <div v-for="(classification, index) in classifications" :key="index" class="checkbox">
            <input type="checkbox" :id="'classification-' + index" :value="classification" v-model="selectedClassifications" />
            <label :for="'classification-' + index">{{ classification }}</label>
          </div>
        </div>
        <div v-if="isEditPage" class="classification-list">
          <p>{{ contributorClassification }}</p>
        </div>
      </div>
    </div>
    <div class="buttons git-hub-style-wrapper">
      <button @click="showModal" class="button reject">Cancel</button>
      <button @click="addButton" v-if="!isEditPage" class="button">Add</button>
      <button @click="editButton" v-if="isEditPage" class="button">Save</button>
    </div>
    <confirmation-modal :visible.sync="confirmationModalVisible" :message="`Are you sure you want to cancel?`" @confirm="handleConfirm" @cancel="handleCancel">
    </confirmation-modal>
  </section>
</template>

<script>
import { ChemspiderDataService, ChemspiderApiService } from "@/api";
import ConfirmationModal from "@/components/shared/confirmation-modal.vue";
import DepositionsHeader from "@/components/cs-depositions/shared/header.vue";
import countryPrefixes from "@/helpers/countryPrefixes.json";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "@/styles/filepond-overwrite.scss";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginImageExifOrientation);

export default {
  components: { DepositionsHeader, ConfirmationModal, FilePond },
  data() {
    return {
      confirmationModalVisible: false,
      file: "",
      isEditPage: this.$route.path.includes("/cs-depositions/datasource/edit") ? true : false,

      selectedPrefix: "", // Almacena el prefijo seleccionado
      countryPrefixes: countryPrefixes,

      fileName: "Select file",
      classifications: [
        "Biological Data",
        "Syntheses",
        "Imaging Compounds",
        "Publishers",
        "Metabolism Data",
        "Screening Data",
        "NIH Substance Repository",
        "Phys. Properties",
        "Protein Data",
        "Chemical Vendors",
        "Theor. Data",
        "Tox/Envir. Data",
        "Personal Data",
        "Publication",
        "Patents",
        "Web Article",
        "Compound Aggreg.",
        "Drugs",
        "Xtal Structures",
        "Natural Products",
        "Spectral Data",
        "Data Aggregators",
        "Virtual library",
        "Safety Data",
      ],
      selectedClassifications: [],

      loading: false,
      datasourceId: this.$route.path.split("/").pop(),
      legacyId: "",
      datasource: {
        Active: "",
        City: "",
        CompanyName: "",
        ContributorClassification: [],
        Country: "",
        DatasourceId: "",
        Description: "",
        Email: "",
        Fax: "",
        Hidden: false,
        Name: "",
        Phone: "",
        State: "",
        StreetAddress: "",
        Trusted: false,
        Website: "",
        ZipCode: "",
      },
      origDatasource: {
        Active: "",
        City: "",
        CompanyName: "",
        ContributorClassification: [],
        Country: "",
        DatasourceId: "",
        Description: "",
        Email: "",
        Fax: "",
        Hidden: false,
        Name: "",
        Phone: "",
        State: "",
        StreetAddress: "",
        Trusted: false,
        Website: "",
        ZipCode: "",
      },
      logo: "",
      origLogo: "",
      logoUpdated: false,
      filePondInit: false,
      settingInitialLogo: false,
      files: [],
      // fake server to simulate loading a 'local' server file and processing a file
      localServer: {
        process: (fieldName, file, metadata, load) => {
          // simulates uploading a file
          load();
        },
        load: (uniqueFileId, load) => {
          fetch(uniqueFileId)
            .then((res) => res.blob())
            .then(load);
        },
      },
    };
  },
  computed: {
    contributorClassification() {
      let options = "";
      this.datasource.ContributorClassification.forEach((o, i) => {
        if (o.ShortName) options = `${options}${i > 0 ? ", " : ""}${o.ShortName}`;
      });

      return options;
    },
  },
  methods: {
    handleFilePondProcessfile: function (error, file) {
      this.files.push(file.file);
      this.logoUpdated = true;
    },
    handleFilePondRemovefile: function () {
      this.files = [];
      this.logoUpdated = false;
    },
    updatePhoneWithPrefix() {
      // Remove any prefix
      const phoneWithoutPrefix = this.datasource.Phone.replace(/^\+\d+ /, "");

      // Update the telephone field with the prefix
      this.datasource.Phone = this.selectedPrefix + " " + phoneWithoutPrefix;
    },
    cancelDatasource() {
      if (!this.isEditPage) {
        this.clearData();
      } else {
        this.datasource = { ...this.origDatasource };
        if (this.origLogo) {
          this.files = [];
          setTimeout(() => {
            this.setOriginalLogo(this.datasource.DatasourceId);
          }, 50);
        }
        this.decodedDescription();
      }
      this.logoUpdated = false;
    },
    clearData() {
      this.datasource = {
        Active: "",
        City: "",
        CompanyName: "",
        ContributorClassification: [],
        Country: "",
        DatasourceId: "",
        Description: "",
        Email: "",
        Fax: "",
        Hidden: false,
        Name: "",
        Phone: "",
        State: "",
        StreetAddress: "",
        Trusted: false,
        Website: "",
        ZipCode: "",
      };
      this.selectedPrefix = "";
      this.selectedClassifications = [];
      this.files = [];
    },
    addButton() {
      for (let selectedClassification of this.selectedClassifications) {
        this.datasource.ContributorClassification.push({ ShortName: selectedClassification });
      }
      this.addDatasource(this.datasource);
    },
    editButton() {
      this.editDatasource(this.legacyId, this.datasource);
    },
    async initDatasource() {
      const response = await ChemspiderDataService.getDatasource(this.datasourceId);
      if (!response.ServiceStatus.HasError) {
        this.legacyId = response.Data.legacyId;
      }
      const [data, logo] = await Promise.all([ChemspiderApiService.getDatasource(this.legacyId), ChemspiderApiService.getDatasourceLogo(this.legacyId)]);

      this.datasource = { ...data.Data };
      this.origDatasource = { ...data.Data };
      this.origLogo = logo.Data;

      // Sets current datasource logo
      if (logo.Data) {
        this.setOriginalLogo(data.Data.DatasourceId);
      }
    },
    setOriginalLogo(datasourceId) {
      this.files.push({
        source: `${process.env.VUE_APP_CHEMSPIDER_BASE_URL}/data-sources/${datasourceId}/${datasourceId}.png`,
        options: {
          type: "local",
        },
      });
    },
    async addDatasource(datasource) {
      try {
        const response = await ChemspiderDataService.addDatasource(datasource);
        if (response.ServiceStatus.HasError) {
          throw new Error("Something went wrong trying to create a data source.");
        }

        if (this.logoUpdated) {
          await this.processLogo(response.Data);
        }
        this.redirectWhenSuccess(response.Data);
      } catch (error) {
        console.error("Error adding datasource:", error);
      }
    },
    async editDatasource(legacyId, datasource) {
      try {
        const response = await ChemspiderDataService.editDatasource(legacyId, datasource);
        if (response.ServiceStatus.HasError) {
          throw new Error("Something went wrong trying to update a data source.");
        }

        if (this.logoUpdated) {
          await this.processLogo(response.Data);
        }
        this.redirectWhenSuccess(response.Data);
      } catch (error) {
        console.error("Error editing datasource:", error);
      }
    },
    async processLogo(datasourceId) {
      const response = await ChemspiderDataService.addLogo(datasourceId, this.files[0]);

      if (response.ServiceStatus.HasError) {
        throw new Error("Something went wrong trying to update a data source logo.");
      }
    },
    redirectWhenSuccess(datasourceId) {
      this.$router.push(`/cs-depositions/datasource/${datasourceId}`);
    },
    decodedDescription() {
      if (!this.datasource.Description) return "";

      const tempElement = document.createElement("div");
      tempElement.innerHTML = this.datasource.Description;

      let cleanText = tempElement.textContent || tempElement.innerText || "";
      this.datasource.Description = cleanText.replace(/<\/?[^>]+(>|$)/g, "");
    },
    showModal() {
      console.log("que falla?");
      this.confirmationModalVisible = true;
    },
    handleConfirm() {
      this.cancelDatasource();
    },
    handleCancel() {
      this.confirmationModalVisible = false;
    },
  },
  async mounted() {
    this.loading = true;
    if (this.isEditPage) {
      await this.initDatasource();
      this.decodedDescription();
      this.loading = false;
    } else {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.file-pond {
  width: 50%;
}

.add-edit-datasource p {
  font-size: 18px;
  color: black;
}

.company-basics {
  display: flex;
  flex-direction: column;
  gap: 26px;
  background-color: #f5f5f5;
  padding: 26px 31px;
}

.file-name-box {
  font-size: 18px;
  color: #a7a7a7;
}

.custom-file {
  display: flex;
  justify-content: space-between;
  padding: 14px;
  align-items: center;
  border: 1px solid #a7a7a7;
  border-radius: 4px;
  height: 56px;
  width: 474px;
}

.company-name-input,
.description-input {
  font-size: 18px;
  padding: 14px;
  border: 1px solid #a7a7a7;
  border-radius: 4px;
  height: 38px;
  width: 100%;
}

.description-input {
  height: 110px;
  resize: none;
}

.description-input::placeholder {
  color: #a7a7a7;
  opacity: 1;
  /* Asegúrate de que la opacidad sea 1 si quieres que sea completamente opaco */
}

.custom-file-label {
  padding: 8px 12px;
  background-color: black;
  font-weight: 700;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
}

.custom-file-input {
  // position: absolute;
  // width: 100%;
  // height: 100%;
  display: none;
  opacity: 0;
  cursor: pointer;
}

.company-contacts1,
.company-contacts2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 26px 31px;
}

.company-contacts2 {
  gap: 36px;
}

.company-contacts1 input[type="text"],
.company-contacts2 input[type="text"] {
  font-size: 18px;
  padding: 14px;
  border: 1px solid #a7a7a7;
  border-radius: 4px;
  height: 38px;
}

.company-contacts2 select {
  height: 38px;
  padding: 8px;
}

.company-contacts-street {
  width: 583px;
}

.company-contacts-city {
  width: 277px;
}

.company-contacts-postcode {
  width: 161px;
}

.company-contacts-website,
.company-contacts-email {
  width: 298px;
}

.classification-list {
  width: 583px;
}

.input-title {
  color: black;
}

.company-contacts2 .checkbox-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.company-contacts2 .checkbox-list .checkbox {
  display: flex;
  align-items: center;
  gap: 4px;
}

.company-contacts2 input[type="checkbox"] {
  width: 24px;
  height: 24px;
}

.company-contacts2 label {
  font-size: 18px;
}

.buttons {
  display: flex;
  gap: 9px;
  margin-left: 31px;
}

.buttons .general {
  background-color: #eef1f4;
  border-color: #d0d7de;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0px 1px 0px 0px #1f23280a;
  font-weight: 500;
}

.buttons .cancel {
  color: #d1242f;
}

.buttons .cancel:hover {
  color: #ffffff;
  background-color: #d1242f;
  border-color: #d1242f;
}

.buttons .add-edit:hover {
  background-color: #e7ebef;
  border-color: #d0d7de;
}
</style>
