import { MerckIndexDataService } from '@/api';
import { WorkflowStatus } from '@/workflow-status';

// This mixin sits between Merck Index's views, components and data service to provide 
// shared functionality, e.g. changing a monograph's workflow status, or polling to 
// verify that a monograph's status change has been projected.

export default {
  data() {
    return {
      statusUpdateCheckAttempts: 0,
      statusUpdateCheckInterval: null,
    }
  },
  computed: {
    isMonographReadOnly() {
      return this.monograph && this.monograph.status !== WorkflowStatus.DRAFT || false;
    },
  },
  methods: {

    // Workflow operations and verification
    async dbSendToDraft(articleId, comment) {
      return MerckIndexDataService.updateMonographStatus(articleId, { status: WorkflowStatus.DRAFT, note: comment });
    },

    async dbSendToReview(articleId, comment) {
      return MerckIndexDataService.updateMonographStatus(articleId, { status: WorkflowStatus.REVIEW, note: comment });
    },

    async dbSendToApproved(articleId, comment) {
      return MerckIndexDataService.updateMonographStatus(articleId, { status: WorkflowStatus.APPROVED, note: comment });
    },

    async dbSendToRejected(articleId, comment) {
      return MerckIndexDataService.updateMonographStatus(articleId, { status: WorkflowStatus.REJECTED, note: comment });
    },

    async dbSendToDiscarded(articleId, comment) {
      return MerckIndexDataService.updateMonographStatus(articleId, { status: WorkflowStatus.DISCARDED, note: comment });
    },

    async dbSendToPublished(articleId, comment) {
      return MerckIndexDataService.updateMonographStatus(articleId, { status: WorkflowStatus.PUBLISHED, note: comment });
    },

    async verifyMonographStatusUpdate(articleId, query) {
      if (this.statusUpdateCheckAttempts < 10) {
        this.statusUpdateCheckAttempts++;
        const result = await MerckIndexDataService.getSearchResults(query);
        // When search results for the updated status include the specified
        // article ID then stop polling and proceed
        if (result.Data && !result.Data.searchResults.some(a => a.articleId === articleId)) {          
          clearInterval(this.statusUpdateCheckInterval);
          return true;
        }
        else {
          return false;
        }
      }
      // After ten attempts, stop polling and proceed anyway
      else {
        clearInterval(this.statusUpdateCheckInterval);
        return true;
      }
    },

    async verifyMonographTitleUpdate(articleId, title, query) {
      if (this.statusUpdateCheckAttempts < 10) {
        this.statusUpdateCheckAttempts++;
        const result = await MerckIndexDataService.getSearchResults(`monographTitle=${title}&${query}`);        
        // When search results for the updated title include the specified
        // article ID then stop polling and proceed
        if (result.Data && result.Data.searchResults.some(a => a.articleId === articleId)) {          
          clearInterval(this.statusUpdateCheckInterval);
          return true;
        }
        else {
          return false;
        }
      }
      // After ten attempts, stop polling and proceed anyway
      else {
        clearInterval(this.statusUpdateCheckInterval);
        return true;
      }
    }
    
  },
};
