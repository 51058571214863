<template>
  <span v-html="citation"></span>
</template>

<script>
  export default {
    name: 'ArticleCitation',
    props: {
      article: {
        type: Object,
        required: true,
      },
    },
    computed: {
      citation() {
        let citation = '';
        citation += this.article.journalTitle ? `<b><i>${this.article.journalTitle}</i></b>, ` : '';
        citation += this.article.subsYear ? `${this.article.subsYear}, ` : '';
        citation += this.article.journalVolume ? `<b>${this.article.journalVolume}</b>, ` : '';
        citation += this.article.firstPage ? this.article.firstPage : '';
        citation += this.article.lastPage ? `-${this.article.lastPage}` : '';
        return citation;
      },
    },
  }
</script>
