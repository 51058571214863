<template>
  <div>
    <p class="mb-1">
      <b>Article title:</b> <span v-html="article.title"></span>
    </p>
    <p class="mb-1">
      <b>Author(s):</b> <ArticleAuthors :authors="article.authors" />
    </p>
    <p class="columns is-mobile is-gapless mb-1">
      <span class="column is-half">
        <b>Journal:</b> {{ article.journalTitle }}
      </span>
      <span class="column is-half">
        <b>DOI:</b> {{ article.doi }}
      </span>
    </p>
    <p class="columns is-mobile is-gapless mb-1">
      <span class="column is-one-quarter">
        <b>Volume:</b> {{ article.journalVolume }}
      </span>
      <span class="column is-one-quarter">
        <b>Issue:</b> {{ article.issueNumber }}
      </span>
      <span class="column is-one-quarter">
        <b>Year:</b> {{ article.subsYear }}
      </span>
      <span class="column is-one-quarter">
        <b>Page range:</b> {{ article.firstPage }}{{ article.lastPage ? - article.lastPage : '' }}
      </span>
    </p>
  </div>
</template>

<script>
import { ArticleAuthors } from '@/components/shared';
export default {
  name: 'ArticleInfo',
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  components: {
    ArticleAuthors,
  },
}
</script>
