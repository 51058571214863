<template>
  <div class="content">
    <h1 class="title is-4">Page not found</h1>
    <h2 class="title is-5">Sorry, we couldn’t find the page you were looking for</h2>
    <p>This could be because:</p>
    <ul>
        <li>The page has been moved, updated or deleted</li>
        <li>You have followed a broken link or typed the address incorrectly</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  metaInfo: {
    title: 'Page not found'
  },
}
</script>
