import { v4 as uuidv4 } from 'uuid';
import { MarinLitDataService } from '@/api';
import { WorkflowStatus } from '@/workflow-status';

// This mixin sits between MarinLit's views, components and data service to provide 
// shared functionality, e.g. creating a new draft, changing an article's workflow 
// status, or polling to verify that an article's status change has been projected.

export default {
  data() {
    return {
      statusUpdateCheckAttempts: 0,
      statusUpdateCheckInterval: null,
    }
  },
  computed: {
    isArticleReadOnly() {
      return this.article && this.article.status !== WorkflowStatus.DRAFT || false;
    },
  },
  methods: {

    // Add, update and retrieve articles
    async dbAddSingleArticle(article) {
      const newArticle = {
        ...article,
        articleId: uuidv4(),
        status: WorkflowStatus.DRAFT,
      };
      return MarinLitDataService.addNewArticle(newArticle);
    },

    async dbUpdateArticle(article) {
      return MarinLitDataService.updateArticle(article);
    },

    async dbGetArticle(articleId) {
      return MarinLitDataService.getArticle(articleId);
    },

    async dbGetCompoundsByArticleId(articleId) {
      return MarinLitDataService.getCompoundsByArticleId(articleId);
    },

    // Workflow operations and verification
    async dbSendToDraft(articleId) {
      return MarinLitDataService.updateArticleStatus(articleId, WorkflowStatus.DRAFT);
    },

    async dbSendToReview(articleId) {
      return MarinLitDataService.updateArticleStatus(articleId, WorkflowStatus.REVIEW);
    },

    async dbSendToApproved(articleId) {
      return MarinLitDataService.updateArticleStatus(articleId, WorkflowStatus.APPROVED);
    },

    async dbSendToRejected(articleId) {
      return MarinLitDataService.updateArticleStatus(articleId, WorkflowStatus.REJECTED);
    },

    async dbSendToDiscarded(articleId) {
      return MarinLitDataService.updateArticleStatus(articleId, WorkflowStatus.DISCARDED);
    },

    async dbSendToPublished(articleId) {
      return MarinLitDataService.updateArticleStatus(articleId, WorkflowStatus.PUBLISHED);
    },

    async verifyArticleStatusUpdate(articleId, sameStatus, query) {
      if (this.statusUpdateCheckAttempts < 10) {
        this.statusUpdateCheckAttempts++;
        const result = await MarinLitDataService.getSearchResults(`${query}`);
        // When search results for the updated status include the specified
        // article ID then stop polling and proceed

        if (result.Data && (result.Data.searchResults.some(a => a.articleId === articleId) == sameStatus)) {
          clearInterval(this.statusUpdateCheckInterval);
          return true;
        }
        else {
          return false;
        }
      }
      // After ten attempts, stop polling and proceed anyway
      else {
        clearInterval(this.statusUpdateCheckInterval);
        return true;
      }
    }

  },
};
