<template>
  <div>
    <progress v-if="showThrobber" class="progress is-link" max="100"></progress>
    <hr v-else />
    <!-- The default type="submit" buttons save the tab contents -->
    <!-- but can be overridden in the individual tab components. -->
    <div class="columns is-mobile">
      <div class="column">
        <slot name="prev">
          <button v-if="prev" id="btn-save-and-go-back" type="submit" class="button" :value="prev" :class="{ 'is-loading': showThrobber }">
            <span class="icon"><i class="fas fa-backward"></i></span>
            <span>Save and go back</span>
          </button>
        </slot>
      </div>
      <div class="column has-text-right" v-if="!isReadOnly">
        <slot name="next">
          <button v-if="next" type="submit" id="btn-save-and-continue" class="button is-success" :value="next" :class="{ 'is-loading': showThrobber }">
            <span>Save and continue</span>
            <span class="icon"><i class="fas fa-forward"></i></span>
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NextPreviousButtons',
  props: {
    showThrobber: {
      type: Boolean,
      default: false,
    },
    isReadOnly:{
      type: Boolean,
      default: false,
    },
    prev: {
      type: String,
      default: '',
      validator: value => ['', 'Basic', 'Classification', 'Compounds', 'Related'].includes(value),
    },
    next: {
      type: String,
      default: '',
      validator: value => ['', 'Classification', 'Compounds', 'Related', 'Preview'].includes(value),
    },
  },
}
</script>
