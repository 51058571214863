<template>
  <section class="datasources">
    <DepositionsHeader />
    <div v-if="loading">
      <Loading />
    </div>
    <div>
      <DepositionsTable :headers="datasourcesHeaders" :contents="contents" :icons="datasourcesIcons"
        :sortList="sortList" />
    </div>
  </section>
</template>

<script>
import DepositionsHeader from '@/components/cs-depositions/shared/header.vue'
import Loading from '@/components/shared/loading.vue';
import DepositionsTable from '@/components/cs-depositions/shared/depositionsTable.vue'
import { ChemspiderDataService } from "@/api";

export default {
  name: 'Depositions',
  components: {
    DepositionsHeader, DepositionsTable, Loading
  },
  data() {
    return {
      loading: false,
      datasourcesHeaders: [
        { title: 'Created date' },
        { title: 'Last updated date' },
        { title: 'Data source' },
        { title: 'Records' },
      ],
      datasourcesIcons: [
        { title: 'Created date', condition: null },
        { title: 'Last updated date', condition: true },
        { title: 'Records', condition: null }
      ],
      contents: []
    }
  },
  methods: {
    async initialiseDepositions() {
      this.datasources = await ChemspiderDataService.getAllDatasources();
      this.setDatasourcesTable(this.datasources.Data);
      // this.depositions.Data.sort((a, b) => new Date(b.depositionDate) - new Date(a.depositionDate));
    },
    setDatasourcesTable(datasourcesData) {
      this.contents = []; // Initialises contents array

      for (let i = 0; i < datasourcesData.length; i++) {
        const data = datasourcesData[i];
        let entry = {}; // Initialises empty object

        if (data.dateCreated) {
          entry['dateCreated'] = data.dateCreated;
        } else {
          entry['dateCreated'] = ''
        }

        if (data.dateUpdated) {
          entry['dateUpdated'] = data.dateUpdated;
        } else {
          entry['dateUpdated'] = ''
        }

        if (data.name) {
          entry['name'] = data.name;
        } else {
          entry['name'] = '';
        }

        if (data.records) {
          entry['records'] = data.records;
        } else {
          entry['records'] = 0;
        }

        if (data.datasourceId) {
          entry['datasourceId'] = data.datasourceId;
        } else {
          entry['datasourceId'] = 0;
        }

        this.contents.push(entry);
      }
    },
    sortList(title, order) {
      if (title == 'Created date' && order == true) this.contents.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
      if (title == 'Created date' && order == false) this.contents.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated));
      if (title == 'Last updated date' && order == true) this.contents.sort((a, b) => new Date(b.dateUpdated) - new Date(a.dateUpdated));
      if (title == 'Last updated date' && order == false) this.contents.sort((a, b) => new Date(a.dateUpdated) - new Date(b.dateUpdated));
      if (title == 'Records' && order == true) this.contents.sort((a, b) => b.compoundCount - a.compoundCount);
      if (title == 'Records' && order == false) this.contents.sort((a, b) => a.compoundCount - b.compoundCount);
    },
  },

  async mounted() {
    this.loading = true;
    await this.initialiseDepositions();
    this.loading = false;
    // this.contents.sort((a, b) => new Date(b.depositionDate) - new Date(a.depositionDate));
  },
};


</script>







