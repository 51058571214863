import { WorkflowStatus } from '@/workflow-status';
import { getField, updateField } from 'vuex-map-fields';
import { getSearchPills, getSearchQueryString, getSearchQueryStringWithoutStatus } from '@/store/search-utils';

export default {

  namespaced: true,

  // STATE
  state: {
    UserList: [],
    SearchParams: {
      // CONTAINER
      'containerName':         { 'label': 'Container name', 'value': '' },
      
      // METADATA
      'status':                { 'value': WorkflowStatus.REVIEW },
      'createdBy':             { 'label': 'Created by', 'value': '' },
      'lastModifiedBy':        { 'label': 'Last modified by', 'value': '' },
      'datePublishedFrom':     { 'label': 'Published after', 'value': '' },
      'datePublishedTo':       { 'label': 'Published before', 'value': '' },
      'dateCreatedFrom':       { 'label': 'Created after', 'value': '' },
      'dateCreatedTo':         { 'label': 'Created before', 'value': '' },
      'dateLastModifiedFrom':  { 'label': 'Last modified after', 'value': '' },
      'dateLastModifiedTo':    { 'label': 'Last modified before', 'value': '' },
    },
  },

  // MUTATIONS
  mutations: {
    updateField,
  },

  // ACTIONS
  actions: {
    
  },

  // GETTERS
  getters: {
    getField,
    getSearchPills,
    getSearchQueryString,
    getSearchQueryStringWithoutStatus,
  },

}
