import { MarinLitDataService } from '@/api';
import {
  SET_ARTICLE_SERVICE_WAITING,
  UPDATE_ARTICLE_SEARCH_RESULTS,
  DELETE_ARTICLE_SEARCH_RESULTS,
} from '@/store/mutation-types';

export default {

  namespaced: true,

  // STATE
  state: {
    SearchResults: [],
    ServiceStatus: {
      AwaitingResponse: false,
      HasError: false,
      Error: null,
    },
  },

  // MUTATIONS
  mutations: {
    [SET_ARTICLE_SERVICE_WAITING](state) {
      state.SearchResults = [];
      state.ServiceStatus.AwaitingResponse = true;
      state.ServiceStatus.HasError = false;
      state.ServiceStatus.Error = '';
    },
    [UPDATE_ARTICLE_SEARCH_RESULTS](state, payload) {
      if (payload.searchResults.Data) {
        state.SearchResults.push(...payload.searchResults.Data);
      }
      else {
        state.SearchResults = [];
      }
      state.ServiceStatus = payload.searchResults.ServiceStatus;
    },
    [DELETE_ARTICLE_SEARCH_RESULTS](state) {
      state.SearchResults = [];
      state.ServiceStatus = {
        AwaitingResponse: false,
        HasError: false,
        Error: null,
      };
    },
  },

  // ACTIONS
  actions: {
    async updateSingleArticleSearchResultAction({ commit }, { value, type }) {
      commit(SET_ARTICLE_SERVICE_WAITING);
      const searchResults = await MarinLitDataService.getSingleArticle(value, type);
      commit(UPDATE_ARTICLE_SEARCH_RESULTS, { searchResults });
    },
    async updateMultipleArticleSearchResultAction({ commit }, value) {
      commit(SET_ARTICLE_SERVICE_WAITING);
      const searchResults = await MarinLitDataService.importArticlesFromBibtex(value);
      commit(UPDATE_ARTICLE_SEARCH_RESULTS, { searchResults });
    },
    clearArticleSearchResultsAction({ commit }) {
      commit(DELETE_ARTICLE_SEARCH_RESULTS);
    }
  },

  // GETTERS
  getters: {
    isLoading: state => state.ServiceStatus.AwaitingResponse,
    newArticles: state => state.SearchResults.filter(r => !r.isDuplicate),
    duplicateArticles: state => state.SearchResults.filter(r => r.isDuplicate),
  },

}
