import { debounce } from 'lodash';
import { ValidationObserver } from 'vee-validate';
import { MarinLitDataService, MerckIndexDataService } from '@/api';
import { TextInput } from '@/components/forms';
import { MessageBlock, SearchPill, StatusLinks, TogglePanel } from '@/components/shared';
import { WorkflowStatus } from '@/workflow-status';

// The MarinLit and Merck Index list pages share search functionality in this mixin.
// It determines which product's API to use based on the current route name.

export default {
  data() {
    return {
      dataServiceMap: {
        'MarinLit': MarinLitDataService,
        'MerckIndex': MerckIndexDataService,
      },
      searchResults: [],
      searchResultsError: false,
      isLoadingSearchResults: true,      
      totalNumberOfSearchResults: 0,
      statusWiseRecords: [{}, {}, {}, {}, {}, {}],
      today: new Date().toISOString().split('T')[0],
    }
  },
  async mounted() {
    if (!this.users.length) {
      const result = await this.dataService.getUsers();
      if (!result.ServiceStatus.HasError) {
        this.users = result.Data.results;
      }
    }
    await this.updateAll();
  },
  computed: {
    dataProduct() {
      return this.$route.name; 
    },
    dataService() {
      return this.dataServiceMap[this.dataProduct];
    },
    numberResultsListHeading(){
      if (this.isLoadingSearchResults) {
        return '';
      }
      else if (this.searchResultsError) {
        return '';
      } else {
        return `${this.totalNumberOfSearchResults === 0 ? 'No' : this.totalNumberOfSearchResults}`;
      }
    },
    searchResultsListHeading() {
      if (this.isLoadingSearchResults) {
        return 'Loading...';
      }
      else if (this.searchResultsError) {
        return 'Error';
      }
      else {
        return `${this.searchResultsHeadingStatusLabel}`;
      }
    },
    searchResultsHeadingStatusLabel() {
      switch (this.status) {
        case WorkflowStatus.REVIEW:
          return `${this.singulariseSearchResult} awaiting approval`;
        case WorkflowStatus.DISCARDED:
          return `discarded ${this.singulariseSearchResult}`;
        default:
          return `${this.status} ${this.singulariseSearchResult}`;
      }
    },
    singulariseSearchResult() {
      switch (this.dataProduct) {
        case 'MarinLit':
          return this.totalNumberOfSearchResults === 1 ? 'article' : 'articles';
        case 'MerckIndex':
          return this.totalNumberOfSearchResults === 1 ? 'monograph' : 'monographs';
        default:
          return '';
      }      
    },
  },
  methods: {
    /* 
      This function will update the link status, then it will update the search results
      return a Promise
    */
    async updateAll() {
      await this.updateStatusLinks().then(async () => await this.updateSearchResults(true))
    },
    async updateStatusLinks() {
      const result = await this.dataService.getStatusWiseResults(this.getSearchQueryStringWithoutStatus);
      if (result.Data) {
        this.statusWiseRecords = result.Data;
              
      }
      else {
        // TODO: Error handling in the UI
      }
    },
    async updateSearchResults(clear) {
      if (clear) {
        this.searchResults = [];
        this.isLoadingSearchResults = true;        
      }

      const result = await this.dataService.getSearchResults(this.getSearchQueryString);
      if (result.Data) {
        this.searchResultsError = false;
        this.searchResults = result.Data.searchResults;
        this.totalNumberOfSearchResults = result.Data.totalNumberOfSearchResults;        
      }
      else {
        this.searchResultsError = true;
        this.searchResults = [];        
      }
      this.isLoadingSearchResults = false;
    },
    async removeSearchParameter(id) {
      this[id] = '';
      await this.updateAll();
    },
    debouncedSearch: debounce(async function() {
      await this.updateAll();
    }, 400),
  },
  components: {
    MessageBlock,
    SearchPill,
    StatusLinks,
    TextInput,
    TogglePanel,
    ValidationObserver,
  },
};
