<template>
  <div class="content">
    
    <div v-if="!authState.isAuthenticated">
      <h2 class="title">Welcome</h2>
      <p class="is-size-5">This workbench allows you to log in using your Okta account.</p>
      <p class="mb-6">
        <button type="button" id="btn-auth-login" class="button is-medium is-link" @click="login">
          <span class="icon"><i class="fas fa-key"></i></span>
          <span>Log in with Okta</span>
        </button>
      </p>
      <hr />
      <p>
        <b>Don&rsquo;t have an account on this workbench yet?</b><br />
        Contact the <a href="https://rsc.service-now.com/technology">Technology Service Desk</a> for assistance.
      </p>
    </div>

    <div v-if="authState.isAuthenticated">
      <h2 class="title">Welcome{{ userName ? ` back, ${userName}!` : '' }}</h2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Home',
  metaInfo: {
    // Don't append default ' - Databases Workbench' to home page <title> element
    titleTemplate: null,
  },
  computed: {
    ...mapGetters('Auth', [
      'userName',
      'userGroups'
    ]),
  },
  methods: {
    async login() {
      await this.$auth.signInWithRedirect();
    },
  }
}
</script>
