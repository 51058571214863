import * as axios from 'axios';
import {
  axiosConfig,
  axiosError,
  parseResponse,
  parseSaveError,
  okResult,
  errorResult
} from '@/api';

const WorkbenchMarinLit = axios.create({
  baseURL: process.env.VUE_APP_MARINLIT_API,
});

WorkbenchMarinLit.interceptors.request.use(axiosConfig, axiosError);

const getStatusWiseResults = async (searchQueryString) => {
  try {
    const response = await WorkbenchMarinLit.get(`/statuswisearticles?${searchQueryString}`);
    let data = parseResponse(response);
    const articles = data;
    return okResult(articles);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getSearchResults = async (searchQueryString) => {
  try {
    const response = await WorkbenchMarinLit.get(`/articles?${searchQueryString}`);
    let data = parseResponse(response);
    const articles = data;
    return okResult(articles);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getArticle = async (articleId) => {
  try {
    const response = await WorkbenchMarinLit.get(`/article/${articleId}`);
    let data = parseResponse(response);
    const article = data;
    return okResult(article);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getArticleReport = async (dateRangeQueryString) => {
  try {
    const response = await WorkbenchMarinLit.get(`/report/articles?${dateRangeQueryString}`, {
      responseType: 'blob',
    });
    return okResult(response);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getCompound = async (recordId) => {
  try {
    const response = await WorkbenchMarinLit.get(`/compound/${recordId}`);
    let data = parseResponse(response);
    const compound = data;
    return okResult(compound);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getTrivialNameSuggestions = async (search) => {
  try {
    const response = await WorkbenchMarinLit.get(`/trivial-names?s=${search}`);
    let data = parseResponse(response);
    const trivialNames = data;
    return okResult(trivialNames);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getTaxonomySuggestions = async (rank, search) => {
  try {
    const response = await WorkbenchMarinLit.get(`/taxonomies/${rank}?s=${search}`);
    let data = parseResponse(response);
    const taxonomies = data;
    return okResult(taxonomies);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getCompoundDataFromMolFile = async (formData) => {
  try {
    const response = await WorkbenchMarinLit.post('/compound/extract-mol-data', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    let data = parseResponse(response);
    const compoundData = data;
    return okResult(compoundData);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}
const getCompoundsByArticleId = async (articleId) => {
  try {
    const response = await WorkbenchMarinLit.get(`/compounds-by-articleid?articleId=${articleId}`);
    let data = parseResponse(response);
    const compounds = data;
    return okResult(compounds);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getCompoundsByInchiKey = async (inchiKey, recordId) => {
  try {
    const response = await WorkbenchMarinLit.get(`/compounds-by-inchi-key?inchiKey=${inchiKey}&ignoreSubstanceRecordId=${recordId}`);
    let data = parseResponse(response);
    const duplicates = data;
    return okResult(duplicates);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getUsers = async () => {
  try {
    const response = await WorkbenchMarinLit.get('/users');
    let data = parseResponse(response);
    const users = data;
    return okResult(users);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const addNewArticle = async (article) => {
  try {
    const response = await WorkbenchMarinLit.post('/article', article);
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const addNewArticles = async (articles) => {
  try {
    const response = await WorkbenchMarinLit.post('/articles', articles);
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const updateArticle = async (article) => {
  try {
    const response = await WorkbenchMarinLit.put(`/article/${article.articleId}`, article);
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const updateArticleStatus = async (articleId, status) => {
  try {
    const response = await WorkbenchMarinLit.put(`/change-status?articleId=${articleId}&status=${status}`);
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const addOrUpdateCompound = async (formData) => {
  try {
    const response = await WorkbenchMarinLit.post('/compound', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const deleteCompound = async (recordId) => {
  try {
    const response = await WorkbenchMarinLit.delete(`/compound/${recordId}`);
    let data = parseResponse(response);
    const parsedResponse = data;
    return okResult(parsedResponse);
  } catch (error) {
    let errorResponse = parseSaveError(error);
    return errorResponse;
  }
}

const importArticlesFromBibtex = async (bibtexCitations) => {
  try {
    const response = await WorkbenchMarinLit.post('/import-articles-from-bibtex', bibtexCitations);
    let data = parseResponse(response);
    const articles = data;
    return okResult(articles);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getSingleArticle = async (value, type) => {
  try {
    // Type must be 'doi' or 'pubMedId'
    const response = await WorkbenchMarinLit.get(`/import-article?${type}=${value}`);
    let data = parseResponse(response);
    const article = data;
    return okResult(article);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const requestAnnualReviewExport = async (yearPublished) => {
  try {
    const response = await WorkbenchMarinLit.post(`/report/annual-review?year=${yearPublished}`);
    if (response.status !== 200) {
      throw Error(response.message);
    }
    return okResult();
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getAnnualReviews = async () => {
  try {
    const response = await WorkbenchMarinLit.get('/report/annual-reviews');
    let data = parseResponse(response);
    const fileNames = data;
    return okResult(fileNames);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

const getAnnualReview = async (fileName) => {
  try {
    const response = await WorkbenchMarinLit.get(`/report/annual-reviews/${fileName}`, {
      responseType: 'blob',
    });
    return okResult(response);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
}

export const MarinLitDataService = {
  getStatusWiseResults,
  getSearchResults,
  getArticle,
  getArticleReport,
  getCompound,
  getTrivialNameSuggestions,
  getTaxonomySuggestions,
  getCompoundDataFromMolFile,
  getCompoundsByArticleId,
  getCompoundsByInchiKey,
  getUsers,
  addNewArticle,
  addNewArticles,
  updateArticle,
  updateArticleStatus,
  addOrUpdateCompound,
  deleteCompound,
  importArticlesFromBibtex,
  getSingleArticle,
  requestAnnualReviewExport,
  getAnnualReviews,
  getAnnualReview,
}
