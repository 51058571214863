<template>
  <section class="depositions">
    <DepositionsHeader />
  </section>
</template>

<script>
import DepositionsHeader from '@/components/cs-depositions/shared/header.vue'

export default {
  name: 'Depositions',
  components: {
    DepositionsHeader
  }
};


</script>



