<template>
  <div id="app">
    <header id="header" class="has-background-light pt-5">
      <div class="container">
        <h1 id="header-title" class="title is-size-4">
          <router-link :to="{ path: '/' }" class="has-text-dark">
            Databases Workbench
            <span v-if="environment" class="tag is-link ml-1" style="vertical-align: bottom;">
              {{ environment }}
            </span>
          </router-link>
        </h1>
        <img id="header-logo" class="rsc-logo" alt="Royal Society of Chemistry"
          src="https://www.rsc-cdn.org/oxygen/assets/logo/rsc-logo-230.png" />
        <nav class="tabs is-boxed">
          <ul id="header-nav">
            <router-link v-for="tab in navigation" tag="li" :key="tab.path" :to="tab.path" :exact="tab.exact">
              <a :id="`header-nav-${tab.id.toLowerCase()}`">{{ tab.label }}</a>
            </router-link>
          </ul>
        </nav>
      </div>
    </header>
    <div class="container py-6">
      <UserInfo />
      <router-view :id="this.$route.meta.elementId" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { UserInfo } from '@/components/auth';
export default {
  name: 'App',
  metaInfo: {
    // Fallback <title> if view component doesn't specify a metaInfo.title
    title: 'Databases Workbench',
    titleTemplate: '%s - Databases Workbench',
  },
  data() {
    return {
      tabs: [
        {
          id: 'MarinLit',
          label: 'MarinLit',
          path: '/marinlit',
          exact: false,
        },
        {
          id: 'MerckIndex',
          label: 'Merck Index',
          path: '/merckindex',
          exact: false,
        },
        {
          id: 'MerckIndex',
          label: 'CS Depositions',
          path: '/cs-depositions',
          exact: false
        }
      ],
    }
  },
  computed: {
    ...mapGetters('Auth', [
      'userProducts',
    ]),
    environment() {
      return process.env.NODE_ENV === 'production' ? process.env.VUE_APP_ENVIRONMENT : '';
    },
    navigation() {
      const links = this.tabs.filter(tab => this.userProducts.includes(tab.id));
      console.log(links)
      links.unshift({ id: 'CS Depositions', label: 'CS Depositions', path: '/cs-depositions', exact: true }); // TODO: remove! 
      links.unshift({ id: 'Home', label: 'Home', path: '/', exact: true });
      return links;
    },
  },
  methods: {
    ...mapActions('Auth', [
      'setCurrentUser',
      'setUserGroups',
    ]),
    async updateAuth() {
      if (this.authState.isAuthenticated) {
        this.setCurrentUser(await this.$auth.getUser());
        this.setUserGroups(this.authState.accessToken.claims.groups_workbench);
      }
    },
  },
  watch: {
    // Wait until authState is ready before updating user info
    'authState.isPending': function () {
      this.updateAuth();
    },
  },
  components: {
    UserInfo,
  },
}
</script>

<style lang="scss">
@import '@/styles/index.scss';
</style>
