<template>
  <a :href="doiLink" target="_blank" rel="noopener noreferrer">
    {{ doiLink }}
  </a>
</template>

<script>
export default {
  name: 'ArticleDoiLink',
  props: {
    doi: {
      type: String,
      required: true,
      validator: v => v.startsWith('10.'),
    },
  },
  computed: {
    doiLink() {
      return `https://doi.org/${this.doi}`; 
    },
  },
};
</script>
